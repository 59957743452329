import {
  Box,
  Heading,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { EmrRemove } from "@medstonetech/slate-icons";
import { AddRounded } from "icons";
import { ChartForm } from "modules/charts-shared";
import { useState } from "react";
import {
  Control,
  FieldValues,
  useFieldArray,
  UseFormSetValue,
} from "react-hook-form";
import { Button, DividerList, SearchBar } from "shared";
import { debounce } from "throttle-debounce";

type AddCustomRowsModalProps = Pick<
  UseDisclosureReturn,
  "isOpen" | "onClose"
> & {
  control: Control<ChartForm>;
  chartCode: string;
  sectionCode: string;
  subsectionCode: string;
  subsectionLabel: string;
  customOptions: string[];
  currentOrder: number;
  setValue: UseFormSetValue<FieldValues>;
};

const AddCustomRowsModalContent = ({
  onClose,
  control,
  subsectionLabel,
  customOptions,
  subsectionCode,
  chartCode,
  sectionCode,
  currentOrder,
  setValue,
}: AddCustomRowsModalProps) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [search, setSearch] = useState("");

  const debouncedSearch = debounce(1000, setSearch);

  const { append } = useFieldArray({
    control,
    name: `${subsectionCode}.customRows` as never,
  });

  const onDone = () => {
    const newRows = selectedOptions.map((option, index) => {
      const crossOptionCode = `${subsectionCode}.${chartCode}${sectionCode}${subsectionCode}A${String(
        currentOrder + index * 2 + 1
      ).padStart(3, "0")}C-${Date.now()}`;
      setValue(crossOptionCode, "Positive");
      return {
        order: currentOrder + index * 2 + 2,
        customAnswers: [
          {
            order: 0,
            qCode: crossOptionCode,
            type: "CrossOption",
            label: option,
          },
          {
            order: 1,
            qCode: `${subsectionCode}.${chartCode}${sectionCode}${subsectionCode}A${String(
              currentOrder + index * 2 + 2
            ).padStart(3, "0")}C-${Date.now()}`,
            type: "Textfield",
            label: "",
          },
        ],
      };
    });
    append(newRows);
    onClose();
  };

  const filteredOptions = customOptions.filter(
    (option) =>
      !selectedOptions.includes(option) && option.includes(search.toLowerCase())
  );

  return (
    <>
      <ModalHeader
        display="flex"
        justifyContent="space-between"
        fontSize="1rem"
        padding="20px 16px 30px"
      >
        <Box flex={1} display="flex" justifyContent="flex-start">
          <Button variant="label" onClick={onClose} color="blue">
            Cancel
          </Button>
        </Box>
        <Heading fontWeight="600" fontSize="1.0625rem">
          {subsectionLabel}
        </Heading>
        <Box flex={1} display="flex" justifyContent="flex-end">
          <Button variant="label" color="blue" onClick={onDone}>
            Done
          </Button>
        </Box>
      </ModalHeader>
      <ModalBody padding="0 15px 15px">
        <Box margin="20px 0">
          <SearchBar
            placeholder="Search"
            height="36px"
            bg="gray.350"
            onChange={(e) => debouncedSearch(e.target.value)}
          />
        </Box>
        <Heading
          fontSize="15px"
          fontWeight="500"
          color="gray.600"
          marginBottom="10px"
        >
          Selected
        </Heading>

        <Box
          alignItems="center"
          padding="8px 20px"
          borderRadius="10px"
          bg="white"
        >
          <DividerList dividerLeftMargin="28px">
            {!selectedOptions.length && (
              <Box
                display="flex"
                justifyContent="center"
                width="100%"
                fontWeight="600"
                fontSize="17px"
                color="gray.700"
              >
                None Selected
              </Box>
            )}
            {selectedOptions.map((option, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="start"
                alignItems="center"
                gap={4}
                py={2}
              >
                <IconButton
                  aria-label="remove selected Nurse"
                  variant="icon"
                  icon={<Icon as={EmrRemove} color="red" fontSize="1.375rem" />}
                  onClick={() =>
                    setSelectedOptions((prev) =>
                      prev.filter((x) => x !== option)
                    )
                  }
                />
                <Text fontWeight="500" fontSize="16px">
                  {option}
                </Text>
              </Box>
            ))}
          </DividerList>
        </Box>

        {!!filteredOptions.length && (
          <Box
            alignItems="center"
            padding="8px 20px"
            borderRadius="10px"
            bg="white"
            mt="20px"
          >
            <DividerList dividerLeftMargin="28px">
              {filteredOptions.map((option, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="start"
                  alignItems="center"
                  gap={4}
                  py={2}
                >
                  <IconButton
                    aria-label="remove selected Nurse"
                    variant="icon"
                    icon={
                      <Icon as={AddRounded} color="green" fontSize="1.375rem" />
                    }
                    onClick={() =>
                      setSelectedOptions((prev) => [...prev, option])
                    }
                  />
                  <Text fontWeight="500" fontSize="16px">
                    {option}
                  </Text>
                </Box>
              ))}
            </DividerList>
          </Box>
        )}
      </ModalBody>
    </>
  );
};

const AddCustomRowsModal = (props: AddCustomRowsModalProps) => {
  const { isOpen } = props;

  return (
    <Modal {...props} motionPreset="slideInRight" blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent
        bg="gray.200"
        maxW="unset"
        width="540px"
        containerProps={{ justifyContent: "flex-end", alignItems: "center" }}
        height="98%"
        margin="0"
        overflowY="auto"
        overflowX="hidden"
        mr="10px"
      >
        {isOpen && <AddCustomRowsModalContent {...props} />}
      </ModalContent>
    </Modal>
  );
};

export { AddCustomRowsModal };
