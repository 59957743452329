import { AxiosError, AxiosResponse } from "axios";
import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { UseQueryOptions } from "react-query";

type TriageIvMedicationCountersResponse = {
  ordered: number;
  inProcess: number;
  completed: number;
};

function useIvAndMedicationCounters(
  encounterId: string,
  options: UseQueryOptions<
    AxiosResponse<TriageIvMedicationCountersResponse>,
    AxiosError
  >
) {
  return useAxiosQuery(
    ["ivAndMedicationCounters", { encounterId }],
    () =>
      axiosChartInstance.get<TriageIvMedicationCountersResponse>(
        `v1/triage/iv-medication/${encounterId}/counters`
      ),
    options
  );
}

export { useIvAndMedicationCounters };
export type { TriageIvMedicationCountersResponse };
