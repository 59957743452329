import * as React from "react";

import { AxiosError, AxiosResponse } from "axios";
import {
  ChartForm,
  ChartFormSubsection,
  ChartFormSubsectionPayload,
  ChartRouteBaseParams,
  mapFormToPayload,
  mapPayloadToFormObject,
  nextRoutesNurse,
  nextRoutesProvider,
} from "modules";
import { SubmitHandler, useForm } from "react-hook-form";
import { UseMutationResult, UseQueryResult } from "react-query";

import { useUpdateAllergies } from "api";
import { useChartTrackingContext } from "contexts";
import { useToast } from "hooks";
import { formMessages } from "messages";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { extractApiErrorMessage } from "utils";

type UseSharedChartFormProps = {
  sectionLabel: string;
  useGetSharedChartForm: (
    encounterId: string
  ) => UseQueryResult<
    AxiosResponse<ChartFormSubsection[]>,
    AxiosError<unknown>
  >;
  useUpdateSharedChartForm: (
    encounterId: string
  ) => UseMutationResult<
    AxiosResponse<unknown>,
    AxiosError<unknown>,
    ChartFormSubsectionPayload[],
    unknown
  >;
  allergiesSections?: { sections: string[]; ignoreNames?: string[] };
  sectionCode: string;
};

function useSharedChartForm(props: UseSharedChartFormProps) {
  const {
    sectionLabel,
    useGetSharedChartForm,
    useUpdateSharedChartForm,
    allergiesSections,
    sectionCode,
  } = props;
  const { encounterId = "", "*": chartName = "" } =
    useParams<ChartRouteBaseParams>();
  const { pathname } = useLocation();
  const useFormContext = useForm<ChartForm>();
  const navigate = useNavigate();
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormContext;
  const [nextRoute, setNextRoute] = React.useState<string | null>(null);
  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useGetSharedChartForm(encounterId);
  const { mutateAsync: updateChartApplication, isLoading: isUpdateIsLoading } =
    useUpdateSharedChartForm(encounterId);
  const {
    mutateAsync: updateAllergies,
    isLoading: isUpdatingAllergiesLoading,
  } = useUpdateAllergies(encounterId);

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const toast = useToast();
  const updateIsLoading =
    isUpdateIsLoading || isUpdatingAllergiesLoading || isTrackingLoading;

  const onSubmit: SubmitHandler<ChartForm> = React.useCallback(
    async (formObject) => {
      try {
        const payload = mapFormToPayload(formObject);

        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            sectionId: sectionCode,
            chartType: "shared",
          }
        );

        // If the allergies options are modified, the allergies warning needs to be updated
        if (allergiesSections) {
          const validAllergiesSections = payload.filter((section) =>
            allergiesSections.sections.includes(section.code)
          );
          if (
            !!validAllergiesSections.find(
              (allergiesSection) =>
                !!allergiesSection.answers.find(
                  (answer) =>
                    !(allergiesSections.ignoreNames || []).includes(
                      answer.qCode
                    ) &&
                    !!answer.value &&
                    answer.value !== "Negative"
                )
            )
          )
            await updateAllergies({ allergies: "Yes" });
          else await updateAllergies({ allergies: "No" });
        }
        await updateChartApplication(payload);
        // Reset form with latest values to set isDirty to false
        reset(formObject);
        toast({ description: formMessages.updateSuccess(sectionLabel) });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      trackChartInteractions,
      data?.data,
      encounterId,
      sectionCode,
      allergiesSections,
      updateChartApplication,
      reset,
      toast,
      sectionLabel,
      updateAllergies,
    ]
  );

  const onSaveAndNext = React.useCallback(() => {
    const nextRoutes = pathname.includes("triage")
      ? nextRoutesNurse
      : nextRoutesProvider;
    const route = nextRoutes[nextRoutes.indexOf(chartName || "") + 1];

    handleSubmit(onSubmit)().then(() => {
      setNextRoute(`./../${route}`);
    });
  }, [chartName, onSubmit, handleSubmit, pathname]);

  React.useEffect(() => {
    if (nextRoute && !isDirty) {
      navigate(nextRoute);
      setNextRoute(null);
    }
  }, [nextRoute, navigate, isDirty]);

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  const value = React.useMemo(
    () => ({
      encounterId,
      useFormContext,
      getIsLoading,
      updateIsLoading,
      onSubmit: handleSubmit(onSubmit),
      onSaveAndNext,
    }),
    [
      handleSubmit,
      onSubmit,
      encounterId,
      useFormContext,
      getIsLoading,
      updateIsLoading,
      onSaveAndNext,
    ]
  );

  return value;
}

export { useSharedChartForm };
export type { UseSharedChartFormProps };
