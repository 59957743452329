import {
  Box,
  Button,
  chakra,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";
import { StaffAvatar, Textarea, ToolbarHeader } from "shared";
import { ReasonSelector, ReasonSelectorItem } from "./ReasonSelector";
import { Cancellation } from "modules/orders/types/cancellation";
import { UseUsersResponseItem } from "modules/identity";

type CancellationDetailModalProps = {
  isModalOpen: boolean;
  onModalClose: () => void;
  cancellation: Cancellation;
  cancelledBy?: UseUsersResponseItem;
};

type ButtonCancelOrderModalProps = {
  cancellation: Cancellation;
};

function ButtonCancellationDetailModal(props: ButtonCancelOrderModalProps) {
  const { cancellation } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <Button variant="link" color="blue" minW="50px" p="0" onClick={onOpen}>
        See Reason
      </Button>
      <CancellationDetailModal
        isModalOpen={isOpen}
        onModalClose={onClose}
        cancellation={cancellation}
      />
    </>
  );
}

type CancelledByInfoProps = {
  cancelledBy: UseUsersResponseItem;
};

function CancelledByInfo({ cancelledBy }: CancelledByInfoProps) {
  return (
    <Box
      w="100%"
      px={4}
      py={3}
      display="flex"
      justifyContent="space-between"
      bgColor="white"
      alignItems="center"
      borderRadius="10px"
    >
      <Text color="gray.700">Canceled by</Text>
      <Box display="flex" justifyContent="space-between" gap={2}>
        <StaffAvatar
          w="32px"
          h="32px"
          size="xs"
          fontSize=".75rem"
          userName={cancelledBy.fullName}
          profileUrl={cancelledBy.pictureUrl}
        />
        <VStack spacing="0" alignItems="flex-start" minW="80px">
          <Box
            fontSize="1.0625rem"
            fontWeight="600"
            lineHeight="1.5rem"
            color="black"
          >
            {cancelledBy.fullName}
          </Box>
          <Box
            fontSize="0.875rem"
            fontWeight="400"
            lineHeight="1rem"
            color="gray.700"
          >
            {cancelledBy.teams}
          </Box>
        </VStack>
      </Box>
    </Box>
  );
}

function CancellationDetailModalContent(props: CancellationDetailModalProps) {
  const { onModalClose, cancellation, cancelledBy } = props;

  return (
    <>
      <ModalHeader>
        <ToolbarHeader
          titleText={
            <chakra.span fontSize="1.0625rem" fontWeight="600" color="black">
              Reason Canceled
            </chakra.span>
          }
          rightButtons={[
            <Button key="doneBtn" onClick={onModalClose}>
              Close
            </Button>,
          ]}
        />
      </ModalHeader>
      <ModalBody p="0 15px 19px">
        <VStack
          justifyContent="center"
          spacing="0px"
          my="45px"
          fontSize="1.0625rem"
          fontWeight="500"
          color="gray.600"
        >
          {cancelledBy ? (
            <CancelledByInfo cancelledBy={cancelledBy} />
          ) : (
            <>
              <chakra.span width="100%" textAlign="center">
                You must add a reason
              </chakra.span>
              <chakra.span width="100%" textAlign="center">
                why you are cancelling this order.
              </chakra.span>
            </>
          )}
        </VStack>
        <ReasonSelector
          mb="25px"
          value={cancellation?.reason || undefined}
          isReadOnly
        >
          <ReasonSelectorItem value="Mistake" height="60px">
            <chakra.span fontWeight="500" fontSize="1.0625rem">
              Ordered by mistake.
            </chakra.span>
          </ReasonSelectorItem>
          <ReasonSelectorItem value="Duplicate" height="60px">
            <chakra.span fontWeight="500" fontSize="1.0625rem">
              Duplicate Order.
            </chakra.span>
          </ReasonSelectorItem>
          <ReasonSelectorItem value="Provider" height="60px">
            <chakra.span fontWeight="500" fontSize="1.0625rem">
              Canceled by Dr’s request.
            </chakra.span>
          </ReasonSelectorItem>
          <chakra.span
            pl="10px"
            width="100%"
            textAlign="start"
            fontSize="0.9375rem"
            fontWeight="500"
            color="gray.700"
          >
            Other reason:
          </chakra.span>
          <ReasonSelectorItem value="Other">
            <Textarea
              bg="white"
              borderWidth="0px"
              w="100%"
              minH="180px"
              fontSize="1.0625rem"
              placeholder="Type reason here"
              value={cancellation?.other || ""}
              isReadOnly
            />
          </ReasonSelectorItem>
        </ReasonSelector>
      </ModalBody>
    </>
  );
}

function CancellationDetailModal(props: CancellationDetailModalProps) {
  const { isModalOpen, onModalClose, cancellation, cancelledBy } = props;

  return (
    <Modal isOpen={isModalOpen} onClose={onModalClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="gray.200" maxWidth="540px">
        {isModalOpen && (
          <CancellationDetailModalContent
            isModalOpen={isModalOpen}
            onModalClose={onModalClose}
            cancellation={cancellation}
            cancelledBy={cancelledBy}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export { CancellationDetailModal, ButtonCancellationDetailModal };
