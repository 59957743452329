import { axiosOrderInstance } from "config";
import {
  OrderContrastTimerStatus,
  OrderFormElementCategory,
  OrderFormElementType,
  OrderGroup,
  OrderStatus,
  OrderType,
} from "enums";
import { Contrast } from "modules/orders/types";
import { useInfiniteQuery } from "react-query";
import { PaginationPayload, PaginationResponse } from "types";

type UseOrdersProps = PaginationPayload & {
  orderStatus?: OrderStatus;
  search?: string;
  orderGroup?: OrderGroup;
};

type ContrastTimer = {
  contrastTimer: string;
  constrastTimerStatus: OrderContrastTimerStatus;
};

type OrdersListItemResponse = {
  id: string;
  pictureUrl: string;
  description: string;
  type: OrderType;
  status: OrderStatus;
  patientFullName: string;
  providerFullName: string;
  timer: string;
  encounterId: string;
  orderedBy: string;
  timerId: string;
  gender?: string;
  age?: number;
  constrastTimer?: ContrastTimer;
  contrast?: Contrast;
  elementType: OrderFormElementType;
  elementCategory: OrderFormElementCategory;
};

type UseOrdersResponse = PaginationResponse<OrdersListItemResponse>;

function useOrders(props: UseOrdersProps) {
  const { size = 10, page = 1, search, orderStatus, orderGroup } = props;

  return useInfiniteQuery(
    ["orders", { search, orderStatus, orderGroup }],
    ({ pageParam = page }) =>
      axiosOrderInstance.get<UseOrdersResponse>("v1", {
        params: {
          pageSize: size,
          page: pageParam,
          search,
          orderStatus,
          orderGroup,
        },
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.currentPage < lastPage.data.totalPages) {
          return lastPage.data.currentPage + 1;
        }
        return undefined;
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.data.currentPage > 1) {
          return firstPage.data.currentPage - 1;
        }
        return undefined;
      },
    }
  );
}

export type { UseOrdersResponse, OrdersListItemResponse };
export { useOrders };
