import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseChartApplicationFileParams = {
  encounterId: string;
  chartCode: string;
};

function useChartApplicationFile(params: UseChartApplicationFileParams) {
  const { encounterId, chartCode } = params;
  return useAxiosQuery(
    ["chartApplicationFile", { encounterId, chartCode }],
    () =>
      axiosChartInstance.get<Blob>(
        `v1/chart-application/${encounterId}/${chartCode}/file`,
        { responseType: "blob" }
      )
  );
}

export type { UseChartApplicationFileParams };
export { useChartApplicationFile };
