import { useAuth0 } from "@auth0/auth0-react";
import { Box, BoxProps, chakra, HStack, useDisclosure } from "@chakra-ui/react";
import * as React from "react";
import { UserAvatar } from "shared";
import { SidebarUserModal } from "shared/sidebar/SidebarUserModal";

type NavigationBarProps = {
  title: string;
  icon?: React.ReactElement;
  showAvatar?: boolean;
  leftActions?: React.ReactNode[];
  rightActions?: React.ReactNode[];
} & Omit<BoxProps, "children">;

function NavigationBar(props: NavigationBarProps) {
  const {
    title,
    icon,
    showAvatar = false,
    leftActions,
    rightActions,
    ...restProps
  } = props;
  const { user } = useAuth0();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      height="80px"
      bg="gray.50"
      borderBottom="1px"
      borderColor="gray.450"
      padding="20px 24px"
      {...restProps}
    >
      {showAvatar && <SidebarUserModal isOpen={isOpen} onClose={onClose} />}
      <HStack spacing="29px">{leftActions}</HStack>
      <Box display="flex" alignItems="center" gridGap="0.5rem">
        {icon}
        <chakra.span fontSize="0.9375rem" fontWeight="400" color="black">
          {title}
        </chakra.span>
      </Box>
      <HStack spacing="29px">
        {rightActions}
        {showAvatar && (
          <UserAvatar
            key="avatar"
            size="sm"
            fontSize="1.5rem"
            picture={user?.picture}
            border="1px solid var(--chakra-colors-gray-550)"
            onClick={onOpen}
            _hover={{ cursor: "pointer" }}
          />
        )}
      </HStack>
    </Box>
  );
}

export type { NavigationBarProps };
export { NavigationBar };
