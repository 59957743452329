import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { ProviderChartCode, TriageChartCode } from "enums";
import { useToast } from "hooks";
import { OrderQueue } from "icons";
import { formMessages, genericErrors } from "messages";
import { useSendOrderQueue } from "modules/charts-shared/api";
import { OrderItem } from "modules/charts-shared/types";
import { useParams } from "react-router-dom";
import { extractApiErrorMessage } from "utils";
import { OrderFormCartItem } from "./OrderFormCartItem";
import { OrderHistoryPreview } from "./OrderHistoryPreview";

type OrderFormCartProps = {
  items: OrderItem[];
  onDelete: (id: string) => void;
  onClear: () => void;
  chartCode: TriageChartCode | ProviderChartCode;
  isOrderQueueLoading?: boolean;
  isDeleteOrderQueueLoading?: boolean;
};

function OrderFormCart({
  items,
  onDelete,
  onClear,
  isOrderQueueLoading,
  isDeleteOrderQueueLoading,
}: OrderFormCartProps) {
  const toast = useToast();

  const { encounterId = "" } = useParams();

  const { mutateAsync: sendOrderQueue, isLoading } = useSendOrderQueue({
    encounterId,
  });

  const handleSendOrder = async () => {
    try {
      const orderIds = items.map((item) => item.id);
      await sendOrderQueue(orderIds);
      toast({
        status: "success",
        description: formMessages.createSuccess("Order"),
      });
    } catch (error) {
      toast({
        status: "error",
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  return (
    <>
      <HStack alignItems="center">
        <Icon as={OrderQueue} color="gray.700" mr="15px" w="24px" h="24px" />
        <Text fontSize="1.125rem" fontWeight="bold">
          Order Queue
        </Text>
        <Box flex={1} />
        <Button
          variant="square"
          disabled={items.length === 0}
          onClick={onClear}
          color="gray.700"
          borderColor="gray.700"
          isLoading={isDeleteOrderQueueLoading}
        >
          Clear
        </Button>
        <Button
          variant="square"
          disabled={items.length === 0}
          onClick={handleSendOrder}
          isLoading={isLoading}
        >
          Send
        </Button>
      </HStack>
      <Divider my="10px" ml="48px" />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        rowGap="10px"
      >
        {items.length > 0 ? (
          items.map((item) => (
            <OrderFormCartItem item={item} onDelete={() => onDelete(item.id)} />
          ))
        ) : (
          <Text
            color="gray"
            fontSize="1.125rem"
            fontWeight="bold"
            h="100px"
            display="flex"
            alignItems="center"
          >
            {isOrderQueueLoading ? <Spinner /> : "Queue is empty"}
          </Text>
        )}
      </Box>
      <Divider my="10px" />
      <OrderHistoryPreview />
    </>
  );
}
export { OrderFormCart };
