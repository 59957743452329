import { Box, BoxProps, HStack, Icon } from "@chakra-ui/react";
import { EmrInProcess, EmrRegistration } from "@medstonetech/slate-icons";
import { SquaredChecked } from "icons";
import * as React from "react";

type OrderHeaderCardProps = {
  counter: number;
  title: string;
  icon: React.ReactNode;
} & Omit<BoxProps, "children">;

type DashboardOrderHeaderCardProps = Omit<
  OrderHeaderCardProps,
  "title" | "icon"
>;

function OrderHeaderCard(props: OrderHeaderCardProps) {
  const { counter, icon, title, color, ...restProps } = props;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="18px 20px 18px 28px"
      height="65px"
      width="100%"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.1)"
      borderRadius="10px"
      {...restProps}
    >
      <HStack spacing="23px">
        {React.isValidElement(icon) &&
          React.cloneElement(icon, {
            fontSize: "1.5rem",
            color: color ?? "white",
          })}
        <Box fontSize="1.25rem" fontWeight="600" color={color ?? "white"}>
          {title}
        </Box>
      </HStack>
      <Box
        fontSize="1.875rem"
        fontWeight="600"
        color={color ?? "white"}
        lineHeight="1.375rem"
      >
        {counter}
      </Box>
    </Box>
  );
}

function OrderedOrderHeaderCard(props: DashboardOrderHeaderCardProps) {
  return (
    <OrderHeaderCard
      title="Ordered"
      icon={<Icon as={EmrRegistration} />}
      bg="orange"
      {...props}
    />
  );
}

function InProcessOrderHeaderCard(props: DashboardOrderHeaderCardProps) {
  return (
    <OrderHeaderCard
      title="In Process"
      icon={<Icon as={EmrInProcess} />}
      bg="blue"
      {...props}
    />
  );
}

function CompletedOrderHeaderCard(props: DashboardOrderHeaderCardProps) {
  return (
    <OrderHeaderCard
      title="Completed"
      icon={<Icon as={SquaredChecked} />}
      bg="green"
      {...props}
    />
  );
}
export type { OrderHeaderCardProps, DashboardOrderHeaderCardProps };
export {
  OrderHeaderCard,
  OrderedOrderHeaderCard,
  InProcessOrderHeaderCard,
  CompletedOrderHeaderCard,
};
