import { AxiosResponse } from "axios";
import { EncounterStatus } from "enums";
import {
  IntegrationResponse,
  useUpdateEncounterIntegrations,
} from "modules/reception/api";
import { extractApiErrorMessage } from "utils";
import { useToast } from "hooks";

function useUpdateIntegrationsStatus(encounterId: string) {
  const toast = useToast();
  const { mutateAsync, isLoading } =
    useUpdateEncounterIntegrations(encounterId);

  const updateIntegrationStatus = (newStatus: EncounterStatus) => {
    if (newStatus === "InProcess") {
      mutateAsync(encounterId)
        .then((integrationResponse: AxiosResponse<IntegrationResponse>) => {
          if (
            !integrationResponse?.data?.ayvaResponse?.sentAYVAPatientInd ||
            !integrationResponse?.data?.ayvaResponse?.sentAYVAEncounterInd ||
            !integrationResponse?.data?.ramsoftResponse?.sentHL7ADTMessageInd
          ) {
            const ayvaResponse = !(
              integrationResponse?.data?.ayvaResponse?.sentAYVAPatientInd &&
              integrationResponse?.data?.ayvaResponse?.sentAYVAEncounterInd
            )
              ? "AYVA Error - Error generating ayva connection"
              : "";

            const ramsoftResponse = !integrationResponse?.data?.ramsoftResponse
              ?.sentHL7ADTMessageInd
              ? "AYVA Error - Error generating ramsoft connection"
              : "";
            toast({
              description: `Integrations Request Error: ${ayvaResponse} ${ramsoftResponse}`,
            });
          } else {
            toast({
              description: "Integrations Request Success",
            });
          }
        })
        .catch((integrationsError: unknown | Error) => {
          toast({ description: extractApiErrorMessage(integrationsError) });
        });
    }
  };

  return {
    isLoading,
    updateIntegrationStatus,
  };
}

export { useUpdateIntegrationsStatus };
