import * as React from "react";
import * as Yup from "yup";

import {
  InsuranceBenefitsForm,
  InsuranceCardForm,
  InsuranceGeneralForm,
  InsuranceGuarantorForm,
  InsuranceSection,
  InsuranceType,
  InsuranceVerificationForm,
} from "../types";
import { UseFormReturn, useForm } from "react-hook-form";
import { dateInputSchema, phoneSchema } from "validators";
import { dateWithoutTimezone, enumMapper } from "utils";

import { Loading } from "shared";
import { NO_DATA_PROVIDER_ID } from "../constants";
import { formMessages } from "messages";
import { format } from "date-fns";
import { useEncounterInsurance } from "../api";
import { useQueryClient } from "react-query";
import { useYupValidationResolver } from "hooks";

type EncounterInsuranceContextValue = {
  insuranceGeneralFormContext: UseFormReturn<InsuranceGeneralForm>;
  insuranceCardFormContext: UseFormReturn<InsuranceCardForm>;
  insuranceGuarantorFormContext: UseFormReturn<InsuranceGuarantorForm>;
  insuranceVerificationFormContext: UseFormReturn<InsuranceVerificationForm>;
  insuranceBenefitsFormContext: UseFormReturn<InsuranceBenefitsForm>;
  didEncounterInsuranceFetch?: () => void;
  setReloadSections: (section: InsuranceSection[]) => void;
};
const EncounterInsuranceContext =
  React.createContext<Nullable<EncounterInsuranceContextValue>>(null);

function useEncounterInsuranceContext() {
  const context = React.useContext(EncounterInsuranceContext);

  if (!context) {
    throw new Error(
      "EncounterInsuranceContext value must be defined before using it"
    );
  }
  return context;
}

const INSURANCE_GENERAL_INITIAL: InsuranceGeneralForm = {
  providerId: null,
  isInsuranceFormDisabledByProvider: false,
  pendingPayer: "",
  memberId: "",
  groupNumber: "",
  effectiveDate: null,
  coverageEndDate: null,
  relationToSubscriber: null,
  usePatientContactInfoAsSubscriber: false,
  subscriber: {
    name: "",
    lastName: "",
    gender: null,
    dob: "",
    employer: "",
    phone: "",
    ssn: "",
    employerPhone: "",
  },
  requiredValidation: false,
  isValidated: false,
  isInsuranceInformationValidated: false,
  isGuarantorValidated: false,
};
const insuranceGeneralValidations = {
  providerId: Yup.mixed()
    .nullable()
    .test("valid-provider", "", (value) => {
      if (!value || value === "") return false;

      return true;
    }),
  memberId: Yup.string()
    .nullable()
    .test("valid-member", "", (value) => {
      if (!value || value === "") return false;

      return true;
    }),
  groupNumber: Yup.string().nullable(),
  relationToSubscriber: Yup.mixed().test("valid-relation", "", (value) => {
    if (!value || value === "") return false;

    return true;
  }),
  usePatientContactInfoAsSubscriber: Yup.boolean(),
  subscriber: {
    name: Yup.string()
      .nullable()
      .test("valid-subscriber-name", "", (value) => {
        if (!value || value === "") return false;

        return true;
      }),
    lastName: Yup.string()
      .nullable()
      .test("valid-subscriber-lastname", "", (value) => {
        if (!value || value === "") return false;

        return true;
      }),
    phone: phoneSchema(formMessages.validPhone("Subscriber Phone")).test(
      "valid-subscriber-phone",
      "",
      (value) => {
        if (!value || value === "") return false;

        return true;
      }
    ),
    gender: Yup.mixed()
      .nullable()
      .test("valid-subscriber-gender", "", (value) => {
        if (!value || value === "") return false;

        return true;
      }),
    ssn: Yup.string()
      .transform((o, c) => (o === "" ? null : c))
      .min(9, formMessages.atLeastNCharacters("SSN", 9))
      .nullable(),
    dob: dateInputSchema({
      errMsg: formMessages.valid("Date"),
      maxDate: new Date(),
    }).test("valid-subscriber-dob", "", (value) => {
      if (!value || value === "") return false;

      return true;
    }),
    employer: Yup.string()
      .nullable()
      .test("valid-subscriber-employer", "", (value) => {
        if (!value || value === "") return false;

        return true;
      }),
    employerPhone: phoneSchema(
      formMessages.validPhone("Subscriber Employer Phone")
    ).test("valid-subscriber-employerphone", "", (value) => {
      if (!value || value === "") return false;

      return true;
    }),
  },
};

const insuranceGeneralSchema = Yup.object().shape({
  ...insuranceGeneralValidations,
  subscriber: Yup.object().shape({
    ...insuranceGeneralValidations.subscriber,
  }),
});

// insurance card default values and validations
const INSURANCE_CARD_INITIAL: InsuranceCardForm = {
  insuranceCardFront: null,
  insuranceCardBack: null,
  requiredValidation: false,
  isValidated: false,
};
const insuranceCardValidations = {
  insuranceCardFront: Yup.mixed().required().nullable(),
  insuranceCardBack: Yup.mixed().required().nullable(),
};
const insuranceCardSchema = Yup.object().shape({
  ...insuranceCardValidations,
});

// insurance guarantor address values and validations
const INSURANCE_GUARANTOR_INITIAL: InsuranceGuarantorForm = {
  insuranceGuarantorSameAsFrom: null,
  insuranceGuarantorAddress: {
    address: "",
    aptSiteNumber: "",
    city: "",
    state: null,
    zipCode: "",
    requiredValidation: false,
    isValidated: false,
  },
  insuranceGuarantorInformation: {
    dob: "",
    email: "",
    name: "",
    lastName: "",
    phone: "",
    relationship: null,
  },
  isValidated: false,
};
const insuranceGuarantorValidations = {
  insuranceGuarantorAddress: {
    address: Yup.string()
      .nullable()
      .test("valid-guarantor-address", "", (value) => {
        if (!value || value === "") return false;

        return true;
      }),
    city: Yup.string()
      .nullable()
      .test("valid-guarantor-city", "", (value) => {
        if (!value || value === "") return false;

        return true;
      }),
    state: Yup.mixed()
      .nullable()
      .test("valid-guarantor-state", "", (value) => {
        if (!value || value === "") return false;

        return true;
      }),
    aptSiteNumber: Yup.string().nullable(),
    zipCode: Yup.string()
      .nullable()
      .test("valid-guarantor-zipcode", "", (value) => {
        if (!value || value === "") return false;

        return true;
      }),
  },
  insuranceGuarantorInformation: {
    dob: dateInputSchema({
      errMsg: formMessages.valid("Date"),
      maxDate: new Date(),
    }).test("valid-guarantor-dob", "", (value) => {
      if (!value || value === "") return false;

      return true;
    }),
    email: Yup.string().nullable().email(formMessages.valid("Email")),
    name: Yup.string()
      .nullable()
      .test("valid-guarantor-name", "", (value) => {
        if (!value || value === "") return false;

        return true;
      }),
    lastName: Yup.string()
      .nullable()
      .test("valid-guarantor-lastname", "", (value) => {
        if (!value || value === "") return false;

        return true;
      }),
    phone: phoneSchema(formMessages.validPhone("Phone")).test(
      "valid-guarantor-phone",
      "",
      (value) => {
        if (!value || value === "") return false;

        return true;
      }
    ),
    relationship: Yup.mixed()
      .nullable()
      .test("valid-guarantor-relationship", "", (value) => {
        if (!value || value === "") return false;

        return true;
      }),
  },
  insuranceGuarantorSameAsFrom: Yup.mixed().required(
    formMessages.required("Insurance Guarantor Same As")
  ),
};
const insuranceGuarantorSchema = Yup.object().shape({
  insuranceGuarantorAddress: Yup.object().shape({
    ...insuranceGuarantorValidations.insuranceGuarantorAddress,
  }),
  insuranceGuarantorInformation: Yup.object().shape({
    ...insuranceGuarantorValidations.insuranceGuarantorInformation,
  }),
  insuranceGuarantorSameAsFrom: Yup.mixed().required(
    formMessages.required("Insurance Guarantor Same As")
  ),
});

// insurance verification default values and validations
const INSURANCE_VERIFICATION_INITIAL: InsuranceVerificationForm = {
  verificationMethod: null,
  insuranceStatus: null,
  requiredValidation: false,
  isValidated: false,
};
const insuranceVerificationValidations = {
  verificationMethod: Yup.mixed()
    .nullable()
    .test("valid-verification-method", "", (value) => {
      if (!value || value === "") return false;

      return true;
    }),
  insuranceStatus: Yup.mixed()
    .nullable()
    .test("valid-verification-insurancestatus", "", (value) => {
      if (!value || value === "") return false;

      return true;
    }),
  isValidated: Yup.boolean().equals([true]),
};
const insuranceVerificationSchema = Yup.object().shape({
  ...insuranceVerificationValidations,
});

// insurance benefits default values and validations
const INSURANCE_BENEFITS_INITIAL: InsuranceBenefitsForm = {
  benefitPaymentType: null,
  benefitEntitledToObservation: false,
  benefitPaymentAmountDue: "",
  benefitNotes: "",
  requiredValidation: false,
  isValidated: false,
  benefitHasFile: false,
  usePatientContactInfoAsSubscriber: false,
  isCourtesyBenefit: false,
  isMSEBenefit: false,
};
const insuranceBenefitsValidations = {
  benefitPaymentType: Yup.mixed()
    .nullable()
    .test("valid-benefit-paymenttype", "", (value) => {
      if (!value || value === "") return false;

      return true;
    }),
  benefitPaymentAmountDue: Yup.string()
    .nullable()
    .test("valid-benefit-paymentamount", "", (value) => {
      if (!value || value === "") return false;

      return true;
    }),
  benefitNotes: Yup.string().nullable(),
};
const insuranceBenefitsSchema = Yup.object().shape({
  ...insuranceBenefitsValidations,
});

type EncounterInsuranceProviderProps = React.PropsWithChildren<{
  encounterId: string;
  insuranceType: InsuranceType;
}>;

function EncounterInsuranceProvider(props: EncounterInsuranceProviderProps) {
  const { encounterId, insuranceType, children } = props;
  const queryClient = useQueryClient();

  const loadedRef = React.useRef(false);
  const fetchingRef = React.useRef(false);
  const reloadSections = React.useRef<InsuranceSection[]>([]);

  // general insurance form
  const insuranceGeneralSchemaResolver = useYupValidationResolver(
    insuranceGeneralSchema
  );
  const insuranceGeneralFormContext = useForm<InsuranceGeneralForm>({
    defaultValues: INSURANCE_GENERAL_INITIAL,
    mode: "onChange",
    resolver: insuranceGeneralSchemaResolver,
  });

  // insurance card form
  const insuranceCardSchemaResolver =
    useYupValidationResolver(insuranceCardSchema);
  const insuranceCardFormContext = useForm<InsuranceCardForm>({
    defaultValues: INSURANCE_CARD_INITIAL,
    mode: "onChange",
    resolver: insuranceCardSchemaResolver,
  });

  // insurance guarantor address form
  const insuranceGuarantorSchemaResolver = useYupValidationResolver(
    insuranceGuarantorSchema
  );
  const insuranceGuarantorFormContext = useForm<InsuranceGuarantorForm>({
    defaultValues: INSURANCE_GUARANTOR_INITIAL,
    mode: "onChange",
    resolver: insuranceGuarantorSchemaResolver,
  });

  // insurance verification form
  const insuranceVerificationSchemaResolver = useYupValidationResolver(
    insuranceVerificationSchema
  );
  const insuranceVerificationFormContext = useForm<InsuranceVerificationForm>({
    defaultValues: INSURANCE_VERIFICATION_INITIAL,
    mode: "onChange",
    resolver: insuranceVerificationSchemaResolver,
  });

  // insurance benefits form
  const insuranceBenefitsSchemaResolver = useYupValidationResolver(
    insuranceBenefitsSchema
  );
  const insuranceBenefitsFormContext = useForm<InsuranceBenefitsForm>({
    defaultValues: INSURANCE_BENEFITS_INITIAL,
    mode: "all",
    resolver: insuranceBenefitsSchemaResolver,
  });

  const { data, isLoading, error, isFetching } = useEncounterInsurance({
    encounterId,
    insuranceType,
  });

  const setReloadSections = React.useCallback(
    (sections: InsuranceSection[]) => {
      const currentSections = [...reloadSections.current];
      currentSections.unshift(
        ...sections.filter((sect) => !currentSections.includes(sect))
      );
      reloadSections.current = currentSections;
      queryClient.invalidateQueries(["encounterInsurance", { encounterId }]);
    },
    [encounterId, queryClient]
  );

  const value: EncounterInsuranceContextValue = React.useMemo(
    () => ({
      insuranceGeneralFormContext,
      insuranceCardFormContext,
      insuranceGuarantorFormContext,
      insuranceVerificationFormContext,
      insuranceBenefitsFormContext,
      didEncounterInsuranceFetch: () => {},
      setReloadSections,
    }),
    [
      insuranceGeneralFormContext,
      insuranceCardFormContext,
      insuranceGuarantorFormContext,
      insuranceVerificationFormContext,
      insuranceBenefitsFormContext,
      setReloadSections,
    ]
  );

  React.useEffect(() => {
    if (isFetching) {
      loadedRef.current = false;
      fetchingRef.current = true;
    } else if (fetchingRef.current) {
      fetchingRef.current = false;
      value.didEncounterInsuranceFetch?.();
    }
  }, [isFetching, value]);

  React.useEffect(() => {
    if (data && !loadedRef.current && !isFetching) {
      const sections = reloadSections.current;
      const reloadAll =
        sections.length === 0 || sections.includes("insuranceAll");
      const {
        provider,
        pendingPayer,
        memberId,
        groupNumber,
        effectiveDate,
        coverageEndDate,
        relationToSubscriber,
        usePatientContactInfoAsSubscriber,
        subscriber,
        insuranceGuarantorAddress,
        requiredValidation,
        insuranceStatus,
        verificationMethod,
        benefitPaymentType,
        insuranceGuarantorInformation,
        insuranceGuarantorSameAsFrom,
        isInsuranceInformationValidated,
        isGuarantorValidated,
        isCourtesyBenefit,
        isMSEBenefit,
      } = data.data;

      if (reloadAll || sections.includes("insuranceGeneral"))
        insuranceGeneralFormContext.reset({
          providerId: provider && {
            value: provider?.id,
            label: provider?.displayName,
          },
          isInsuranceFormDisabledByProvider:
            provider?.providerId == NO_DATA_PROVIDER_ID,
          pendingPayer,
          memberId,
          groupNumber,
          effectiveDate: effectiveDate
            ? format(
                dateWithoutTimezone(effectiveDate.toString()),
                "MM-dd-yyyy"
              )
            : null,
          coverageEndDate: coverageEndDate
            ? format(
                dateWithoutTimezone(coverageEndDate.toString()),
                "MM-dd-yyyy"
              )
            : null,
          relationToSubscriber:
            relationToSubscriber &&
            enumMapper.valueToOption(
              "relationToSubscriber",
              relationToSubscriber
            ),
          usePatientContactInfoAsSubscriber,
          subscriber: subscriber && {
            ...subscriber,
            phone: subscriber.phone || "",
            gender:
              subscriber.gender &&
              enumMapper.valueToOption("gender", subscriber.gender),
            employerPhone: subscriber.employerPhone || "",
            dob: subscriber?.dob
              ? format(
                  dateWithoutTimezone(subscriber.dob.toString()),
                  "MM-dd-yyyy"
                )
              : "",
            ssn: subscriber.ssn || "",
          },
          requiredValidation,
          isGuarantorValidated,
          isInsuranceInformationValidated,
        });

      if (
        (reloadAll || sections.includes("insuranceId")) &&
        provider?.providerId == NO_DATA_PROVIDER_ID
      ) {
        insuranceCardFormContext.reset({
          insuranceCardFront: null,
          insuranceCardBack: null,
          isValidated: false,
          requiredValidation: false,
        });
      }

      const { state, ...restInsuranceGuarantorAddress } =
        insuranceGuarantorAddress || { state: null };

      const { dob, relationship, ...restInsuranceGuarantorInformation } =
        insuranceGuarantorInformation || { dob: null, relationship: null };

      if (reloadAll || sections.includes("insuranceGuarantor"))
        insuranceGuarantorFormContext.reset({
          insuranceGuarantorSameAsFrom:
            insuranceGuarantorSameAsFrom &&
            enumMapper.valueToOption(
              "insuranceGuarantorSameAsFrom",
              insuranceGuarantorSameAsFrom
            ),
          insuranceGuarantorAddress: {
            state: state && enumMapper.valueToOption("usState", state),
            ...restInsuranceGuarantorAddress,
            isValidated: false,
            requiredValidation: false,
          },
          insuranceGuarantorInformation: {
            ...restInsuranceGuarantorInformation,
            dob: dob
              ? format(dateWithoutTimezone(dob.toString()), "MM-dd-yyyy")
              : "",
            relationship:
              relationship &&
              enumMapper.valueToOption("relationToPatient", relationship),
          },
        });

      if (reloadAll || sections.includes("insuranceVerification"))
        insuranceVerificationFormContext.reset({
          insuranceStatus: insuranceStatus
            ? enumMapper.valueToOption("insuranceStatus", insuranceStatus)
            : null,
          verificationMethod: verificationMethod
            ? enumMapper.valueToOption("verificationMethod", verificationMethod)
            : null,
          requiredValidation: false,
          isValidated: data.data.isVerificationValidated,
        });

      if (reloadAll || sections.includes("insuranceBenefits"))
        insuranceBenefitsFormContext.reset({
          benefitPaymentType: benefitPaymentType
            ? enumMapper.valueToOption(
                "insurancePaymentType",
                benefitPaymentType
              )
            : null,
          benefitEntitledToObservation: data.data.benefitEntitledToObservation,
          benefitNotes: data.data.benefitNotes,
          benefitPaymentAmountDue: data.data.benefitPaymentAmountDue,
          requiredValidation,
          isValidated: data.data.isBenefitValidated,
          benefitHasFile: data.data.benefitHasFile,
          isCourtesyBenefit,
          isMSEBenefit,
        });

      reloadSections.current = [];
      loadedRef.current = true;
    }
  }, [
    data,
    isFetching,
    insuranceGeneralFormContext,
    insuranceCardFormContext,
    insuranceGuarantorFormContext,
    insuranceVerificationFormContext,
    insuranceBenefitsFormContext,
  ]);

  if (isLoading || isFetching) {
    return <Loading />;
  }

  if (error) {
    return <>Something went wrong</>;
  }

  return (
    <EncounterInsuranceContext.Provider value={value}>
      {children}
    </EncounterInsuranceContext.Provider>
  );
}

export {
  insuranceGeneralValidations,
  insuranceCardValidations,
  insuranceGuarantorValidations,
  insuranceVerificationValidations,
  insuranceBenefitsValidations,
  EncounterInsuranceContext,
  EncounterInsuranceProvider,
  useEncounterInsuranceContext,
};
