import { axiosChartInstance } from "config";
import { OrderGroup } from "enums";
import { useAxiosMutation } from "hooks";
import { UseOrderChartResponseItem } from "modules/provider/api";
import { useQueryClient } from "react-query";

type UpdateOrderChartParams = {
  encounterId: string;
  chartCode: string;
  orderType: OrderGroup;
};

function useUpdateOrderChart(params: UpdateOrderChartParams) {
  const { encounterId, chartCode, orderType } = params;
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateOrderChart", params],
    (payload: UseOrderChartResponseItem[]) =>
      axiosChartInstance.put(`v1/${encounterId}/${chartCode}/order`, payload, {
        params: { orderType },
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(["orderChart", params]),
    }
  );
}

export { useUpdateOrderChart };
