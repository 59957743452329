import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { ChartFormSubsectionPayload } from "modules/charts-shared";
import { useQueryClient } from "react-query";

type UseUpdateChartApplicationParams = {
  encounterId: string;
  chartCode: string;
  sectionId: string;
};

function useUpdateChartApplication(params: UseUpdateChartApplicationParams) {
  const { encounterId, chartCode, sectionId } = params;
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["chartApplication", { chartCode, sectionId }],
    (input: ChartFormSubsectionPayload[]) =>
      axiosChartInstance.put(
        `v1/chart-application/${encounterId}/${chartCode}/${sectionId}`,
        input
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chartApplication", params]);
        queryClient.invalidateQueries(["chartDisposition", params]);
      },
    }
  );
}

export type { UseUpdateChartApplicationParams };
export { useUpdateChartApplication };
