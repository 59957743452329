import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";
import { ChartFormSubsection, OrderHistoryItem } from "modules/charts-shared";

type ChartEkgFormSubsectionResponse = ChartFormSubsection & {
  order?: Partial<OrderHistoryItem>;
  orderId: string;
};

function useEkg(encounterId: string) {
  return useAxiosQuery(["ekg", { encounterId }], () =>
    axiosChartInstance.get<ChartEkgFormSubsectionResponse[]>(
      `v1/chart/${encounterId}/ekg`
    )
  );
}

export { useEkg };
export type { ChartEkgFormSubsectionResponse };
