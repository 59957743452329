import * as React from "react";

import {
  ChartForm,
  ChartRouteBaseParams,
  mapFormToPayload,
  mapPayloadToFormObject,
  nextRoutesNurse,
  nextRoutesProvider,
} from "modules";
import { ProviderChartCode, TriageChartCode } from "enums";
import { SubmitHandler, useForm } from "react-hook-form";
import { useChartApplication, useUpdateChartApplication } from "../api";

import { FormPromptWithSaveProps } from "shared/prompt";
import { extractApiErrorMessage } from "utils";
import { formMessages } from "messages";
import { useChartTrackingContext } from "contexts";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "hooks";
import { useUpdateAllergies } from "api";

type UseChartFormProps = {
  chartCode: TriageChartCode | ProviderChartCode;
  sectionId: string;
  sectionLabel: string;
  allergiesSections?: { sections: string[]; ignoreNames?: string[] };
};

function useChartForm(props: UseChartFormProps) {
  const { chartCode, sectionId, sectionLabel, allergiesSections } = props;
  const { encounterId = "", "*": chartName } =
    useParams<ChartRouteBaseParams>();
  const navigate = useNavigate();
  const useFormContext = useForm<ChartForm>();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext;

  const [nextRoute, setNextRoute] = React.useState<string | null>(null);

  const {
    data,
    isLoading: getIsLoading,
    error: getError,
  } = useChartApplication({
    chartCode,
    encounterId,
    sectionId,
  });
  const { mutateAsync: updateChartApplication, isLoading: isUpdateIsLoading } =
    useUpdateChartApplication({
      chartCode,
      encounterId,
      sectionId,
    });
  const {
    mutateAsync: updateAllergies,
    isLoading: isUpdatingAllergiesLoading,
  } = useUpdateAllergies(encounterId);

  const { trackChartInteractions, isLoading: isTrackingLoading } =
    useChartTrackingContext();

  const toast = useToast();
  const updateIsLoading =
    isUpdateIsLoading || isUpdatingAllergiesLoading || isTrackingLoading;

  const onSubmit: SubmitHandler<ChartForm> = React.useCallback(
    async (formObject) => {
      try {
        const payload = mapFormToPayload(formObject);

        await trackChartInteractions(
          data?.data.map((x) => ({ code: x.code, answers: x.answers })) || [],
          payload,
          {
            encounterId,
            chartCode,
            sectionId,
            chartType: "application",
          }
        );

        // If the allergies options are modified, the allergies warning needs to be updated
        if (allergiesSections) {
          const validAllergiesSections = payload.filter((section) =>
            allergiesSections.sections.includes(section.code)
          );
          if (
            !!validAllergiesSections.find(
              (allergiesSection) =>
                !!allergiesSection.answers.find(
                  (answer) =>
                    !(allergiesSections.ignoreNames || []).includes(
                      answer.qCode
                    ) &&
                    !!answer.value &&
                    answer.value !== "Negative"
                )
            )
          )
            await updateAllergies({ allergies: "Yes" });
          else await updateAllergies({ allergies: "No" });
        }
        await updateChartApplication(payload);
        // Reset form with latest values to set isDirty to false
        reset(formObject);
        toast({ description: formMessages.updateSuccess(sectionLabel) });
      } catch (error) {
        toast({ description: extractApiErrorMessage(error) });
      }
    },
    [
      trackChartInteractions,
      data?.data,
      encounterId,
      chartCode,
      sectionId,
      allergiesSections,
      updateChartApplication,
      reset,
      toast,
      sectionLabel,
      updateAllergies,
    ]
  );

  const onSaveAndNext = React.useCallback(() => {
    const nextRoutes =
      chartCode[0] === "T" ? nextRoutesNurse : nextRoutesProvider;
    const route = nextRoutes[nextRoutes.indexOf(chartName || "") + 1];
    handleSubmit(onSubmit)().then(() => {
      setNextRoute(`./../${route}`);
    });
  }, [chartName, onSubmit, handleSubmit, chartCode]);

  React.useEffect(() => {
    if (nextRoute && !isDirty) {
      navigate(nextRoute);
      setNextRoute(null);
    }
  }, [isDirty, nextRoute, navigate]);

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] =
    React.useCallback(
      async (onComplete) => {
        try {
          const formObjectFromContext = getValues();
          const payload = mapFormToPayload(formObjectFromContext);
          await updateChartApplication(payload);
          toast({ description: formMessages.updateSuccess(sectionLabel) });
          onComplete();
        } catch (error) {
          toast({ description: extractApiErrorMessage(error) });
        }
      },
      [updateChartApplication, toast, sectionLabel, getValues]
    );

  React.useEffect(() => {
    if (data) {
      const form = mapPayloadToFormObject(data.data);
      reset(form);
    }
  }, [data, reset]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  const value = React.useMemo(
    () => ({
      isDirty,
      encounterId,
      useFormContext,
      getIsLoading,
      updateIsLoading,
      onSubmit: handleSubmit(onSubmit),
      onRouteChangeSave,
      onSaveAndNext,
    }),
    [
      isDirty,
      onRouteChangeSave,
      handleSubmit,
      onSubmit,
      encounterId,
      useFormContext,
      getIsLoading,
      updateIsLoading,
      onSaveAndNext,
    ]
  );

  return value;
}

export type { UseChartFormProps };
export { useChartForm };
