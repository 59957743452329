import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type UseUpdateCommonDoseFavoritesDto = {
  id?: string;
  userId?: string;
  favorite: boolean;
  orderFormElementCommonDoseId: string;
};

function useUpdateCommonDoseFavorites() {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateOrderFormFavorites"],
    (body: UseUpdateCommonDoseFavoritesDto[]) =>
      axiosChartInstance.post(`v1/order-form/favorites/doses/add`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["doseFavorites"]);
      },
    }
  );
}
export { useUpdateCommonDoseFavorites };
export type { UseUpdateCommonDoseFavoritesDto };
