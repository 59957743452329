import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { OrderHistoryItem } from "modules/charts-shared";
import { useQueryClient } from "react-query";

type EKGPayloadSection = {
  code: string;
  answers: { qCode: string; value: unknown }[];
  order?: Partial<OrderHistoryItem>;
  orderId?: string;
};

function useUpdateEKG(encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateEkg", { encounterId }],
    (input: EKGPayloadSection[]) =>
      axiosChartInstance.post(`v1/chart/${encounterId}/ekg`, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["ekg", { encounterId }]);
        queryClient.invalidateQueries(["ekgCounters", { encounterId }]);
      },
    }
  );
}

export { useUpdateEKG };
export type { EKGPayloadSection };
