import { axiosSLPatientInstance } from "config";
import { Gender } from "enums";
import { SearchPatientsElement } from "modules/stp-vip/types";
import { useInfiniteQuery } from "react-query";
import { PaginationPayload, PaginationResponse } from "types";

type SearchPatientsResponse = PaginationResponse<SearchPatientsElement>;

type PatientFilter = Partial<{
  firstName: string;
  lastName: string;
  dob: string | Date;
  gender: Gender | null;
  cellphone: string;
  ssn: string;
  mrn: string;
  wristband: string;
}>;

type UseSearchPatientsProps = PatientFilter & PaginationPayload;

function useSearchEncounters(props: UseSearchPatientsProps, stpVip = "stp") {
  const { sort, descending, page = 1, size = 10, ...filters } = props;
  const validFilters = Object.entries(filters).reduce((curr, [k, v]) => {
    if (v) {
      return { ...curr, [k]: v };
    }
    return curr;
  }, {});

  return useInfiniteQuery(
    ["stpVipPatients", props, stpVip],
    ({ pageParam = page }) =>
      axiosSLPatientInstance.get<SearchPatientsResponse>(
        `v1/patient/${stpVip}`,
        {
          params: {
            page: pageParam,
            pageSize: size,
            sortDesc: descending || false,
            orderBy: sort,
            ...validFilters,
          },
        }
      ),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.currentPage < lastPage.data.totalPages) {
          return lastPage.data.currentPage + 1;
        }
        return undefined;
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.data.currentPage > 1) {
          return firstPage.data.currentPage - 1;
        }
        return undefined;
      },
    }
  );
}
export { useSearchEncounters };
export type { PatientFilter, UseSearchPatientsProps };
