import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
  VStack,
} from "@chakra-ui/react";
import { useToast } from "hooks";
import { RoomBedDto, usePatientDetails } from "modules/in-process/api";
import { MedicalStaff } from "modules/in-process/types";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { DividerList, Loading, EncounterAvatar } from "shared";
import { extractApiErrorMessage } from "utils";
import {
  NurseInput,
  RoomInput,
  SupervisorInput,
  TreatingProviderInput,
  ChiefComplaintInput,
  ChargeNurseInput,
  AdmittingProviderInput,
  ObservationProviderInput,
  ScribeInput,
  HUCInput,
} from "./patient-details-inputs";
import { usePermissions } from "contexts";
import { usePatchEncounterField } from "modules/reception/api";
import { genericErrors } from "messages";

type PatientDetailsModalProps = Pick<
  UseDisclosureReturn,
  "isOpen" | "onClose"
> & { encounterId: string };
type PatientDetailsModalContentProps = Pick<
  PatientDetailsModalProps,
  "onClose" | "encounterId"
>;

type PatientDetailsForm = {
  roomBed: RoomBedDto | null;
  nurse: MedicalStaff | null;
  provider: MedicalStaff | null;
  supervisor: MedicalStaff | null;
  chargeNurse: MedicalStaff | null;
  admittingProvider: MedicalStaff | null;
  observationProvider: MedicalStaff | null;
  chiefComplaint: string | undefined;
  huc: MedicalStaff | null;
  scribe: MedicalStaff | null;
};

const boxInputStyles = {
  borderRadius: "10px",
  bg: "white",
  mb: 4,
};

function PatientDetailsModalContent(props: PatientDetailsModalContentProps) {
  const { onClose, encounterId } = props;
  const { data, isLoading, error } = usePatientDetails(encounterId);
  const toast = useToast();
  const { reset, control, register, getValues } = useForm<PatientDetailsForm>();

  const { scope } = usePermissions();
  const isReadOnly = !scope("inprocess").isEditable;

  const {
    mutateAsync: UpdateChiefComplaint,
    isLoading: isUpdateChiefComplaintLoading,
  } = usePatchEncounterField({
    encounterId,
    reloadInfo: true,
    reloadSections: ["inProcess"],
  });

  const handleSaveAndClose = async () => {
    try {
      await UpdateChiefComplaint([
        { path: "chiefComplaint", value: getValues("chiefComplaint") },
      ]);
      onClose();
    } catch (err) {
      toast({
        status: "error",
        description:
          extractApiErrorMessage(err) || genericErrors.fieldSavingError,
      });
    }
  };

  React.useEffect(() => {
    if (data) {
      const {
        roomBed,
        nurse,
        provider,
        supervisor,
        chiefComplaint,
        chargeNurse,
        admittingProvider,
        observationProvider,
        huc,
        scribe,
      } = data.data;

      const prevChiefComplaint = getValues("chiefComplaint");
      reset({
        roomBed,
        nurse,
        provider,
        supervisor,
        chiefComplaint: Boolean(prevChiefComplaint)
          ? prevChiefComplaint
          : chiefComplaint,
        chargeNurse,
        admittingProvider,
        observationProvider,
        huc,
        scribe,
      });
    }
  }, [reset, data, getValues]);

  React.useEffect(() => {
    if (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  }, [error, toast]);

  if (isLoading) {
    return <Loading w="100%" />;
  }

  return data ? (
    <>
      <ModalHeader
        display="flex"
        justifyContent="space-between"
        fontSize="1rem"
        padding="20px 16px 30px"
      >
        <Heading marginLeft="40%" fontWeight="600" fontSize="1.0625rem">
          Patient Details
        </Heading>
        <Box flex={1} display="flex" justifyContent="flex-end">
          <Button
            variant="label"
            onClick={handleSaveAndClose}
            color="blue"
            isLoading={isUpdateChiefComplaintLoading}
            isDisabled={isUpdateChiefComplaintLoading}
          >
            Save & Close
          </Button>
        </Box>
      </ModalHeader>
      <ModalBody padding="0 15px 15px">
        <VStack spacing="14px" marginBottom="35px">
          <EncounterAvatar encounterId={encounterId} w="100px" h="100px" />
          <Box fontSize="1.625rem" fontWeight="500">
            {data.data.fullName}
          </Box>
        </VStack>

        <ChiefComplaintInput encounterId={encounterId} register={register} />

        <Box {...boxInputStyles}>
          <Controller
            render={({ field: { value, ...rest } }) => (
              <RoomInput
                value={value}
                encounterId={encounterId}
                disabled={isReadOnly}
                {...rest}
              />
            )}
            name="roomBed"
            control={control}
          />
        </Box>

        <Box {...boxInputStyles}>
          <DividerList dividerLeftMargin="52px" dividerProps={{ h: "0.2px" }}>
            <Controller
              render={({ field: { value, ...rest } }) => (
                <ChargeNurseInput
                  value={value}
                  encounterId={encounterId}
                  encounterUserType="ChargeNurse"
                  userType="Nurse"
                  onCloseDetailsModal={onClose}
                  disabled={isReadOnly}
                  {...rest}
                />
              )}
              name="chargeNurse"
              control={control}
            />
            <Controller
              render={({ field: { value, ...rest } }) => (
                <NurseInput
                  value={value}
                  encounterId={encounterId}
                  encounterUserType="Nurse"
                  userType="Nurse"
                  onCloseDetailsModal={onClose}
                  disabled={isReadOnly}
                  {...rest}
                />
              )}
              name="nurse"
              control={control}
            />
          </DividerList>
        </Box>

        <Box {...boxInputStyles}>
          <Controller
            render={({ field: { value, ...rest } }) => (
              <HUCInput
                value={value}
                encounterId={encounterId}
                encounterUserType="HUC"
                userType="HUC"
                onCloseDetailsModal={onClose}
                disabled={isReadOnly}
                {...rest}
              />
            )}
            name="huc"
            control={control}
          />
        </Box>

        <Box {...boxInputStyles}>
          <Controller
            render={({ field: { value, ...rest } }) => (
              <ScribeInput
                value={value}
                encounterId={encounterId}
                encounterUserType="Scribe"
                userType="Scribe"
                onCloseDetailsModal={onClose}
                disabled={isReadOnly}
                {...rest}
              />
            )}
            name="scribe"
            control={control}
          />
        </Box>

        <Box {...boxInputStyles}>
          <DividerList dividerLeftMargin="52px" dividerProps={{ h: "0.2px" }}>
            <Controller
              render={({ field: { value, ...rest } }) => (
                <AdmittingProviderInput
                  value={value}
                  encounterId={encounterId}
                  encounterUserType="AdmittingProvider"
                  userType="Provider"
                  onCloseDetailsModal={onClose}
                  disabled={isReadOnly}
                  {...rest}
                />
              )}
              name="admittingProvider"
              control={control}
            />
            <Controller
              render={({ field: { value, ...rest } }) => (
                <TreatingProviderInput
                  value={value}
                  encounterId={encounterId}
                  encounterUserType="Provider"
                  userType="Provider"
                  onCloseDetailsModal={onClose}
                  disabled={isReadOnly}
                  {...rest}
                />
              )}
              name="provider"
              control={control}
            />
          </DividerList>
        </Box>

        <Box {...boxInputStyles}>
          <Controller
            render={({ field: { value, ...rest } }) => (
              <SupervisorInput
                value={value}
                encounterId={encounterId}
                encounterUserType="Supervisor"
                userType="SupervisingProvider"
                onCloseDetailsModal={onClose}
                disabled={isReadOnly}
                {...rest}
              />
            )}
            name="supervisor"
            control={control}
          />
        </Box>

        <Box {...boxInputStyles}>
          <Controller
            render={({ field: { value, ...rest } }) => (
              <ObservationProviderInput
                value={value}
                encounterId={encounterId}
                encounterUserType="ObservationProvider"
                userType="Provider"
                onCloseDetailsModal={onClose}
                disabled={isReadOnly}
                {...rest}
              />
            )}
            name="observationProvider"
            control={control}
          />
        </Box>
      </ModalBody>
    </>
  ) : null;
}

function PatientDetailsModal(props: PatientDetailsModalProps) {
  const { isOpen, onClose, encounterId } = props;

  return (
    <Modal {...props} motionPreset="slideInRight" blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent
        bg="gray.200"
        maxW="unset"
        width="540px"
        containerProps={{ justifyContent: "flex-end", alignItems: "center" }}
        height="98%"
        margin="0"
        overflowY="auto"
        overflowX="hidden"
        mr="10px"
      >
        {isOpen && (
          <PatientDetailsModalContent
            onClose={onClose}
            encounterId={encounterId}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export type { PatientDetailsModalProps, PatientDetailsForm };
export { PatientDetailsModal };
