import { axiosIdentityInstance } from "config";
import { useAxiosQuery } from "hooks";
import { UserType } from "modules";

type UseGetUserResponse = {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  phoneNumber: string;
  pictureUrl: string;
  active: boolean;
  type: string;
  title: string;
  teams: {
    id: string;
    code: string;
    name: string;
    type: UserType;
    other: string;
    permissions: [
      {
        id: "string";
        name: string;
      }
    ];
  }[];
  signature?: string;
  needToChangePassword?: boolean;
};

function useGetUser(
  userId: string,
  options?: { enabled: boolean; currentUser?: boolean }
) {
  const isCurrentUser = !!options?.currentUser;
  const staleTime = isCurrentUser ? { staleTime: 10 * 60 * 1000 } : {};

  return useAxiosQuery(
    ["getUser", { userId }],
    () => axiosIdentityInstance.get<UseGetUserResponse>(`/v1/user/${userId}`),
    { ...options, ...staleTime }
  );
}

export type { UseGetUserResponse };
export { useGetUser };
