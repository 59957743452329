import * as React from "react";

import {
  Divider,
  Modal,
  ModalContent,
  ModalOverlay,
  UseDisclosureReturn,
} from "@chakra-ui/react";

import { STPVipNote } from "./STPVipNote";
import { STPVipNotesModalBody } from "./STPVipNotesModalBody";
import { STPVipNotesModalFooter } from "./STPVipNotesModalFooter";
import { STPVipNotesModalHeader } from "./STPVipNotesModalHeader";
import { useStpVipNotes } from "modules/stp-vip/api";

type StpVipNotesModalProps = {
  title: React.ReactNode;
  patientId: string;
  type: "Stp" | "Vip" | "Other";
} & Pick<UseDisclosureReturn, "isOpen" | "onClose">;

function STPVipNotesModal(props: StpVipNotesModalProps) {
  const { isOpen, onClose, title, patientId, type } = props;

  const modalBodyRef = React.useRef<HTMLDivElement>(null);

  const scrollToBottom = React.useCallback(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTo({ top: modalBodyRef.current.scrollHeight });
    }
  }, []);

  const { data, isLoading } = useStpVipNotes({
    patientId,
    type,
  });

  // scroll to the bottom of the modal when loading data
  React.useEffect(() => {
    if (modalBodyRef.current) {
      scrollToBottom();
    }
  }, [data, scrollToBottom]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        display="flex"
        flexDirection="column"
        height="500px"
        width="540px"
        maxWidth="540px"
        bg="white"
      >
        <STPVipNotesModalHeader onClose={onClose} title={title} />
        <Divider />
        <STPVipNotesModalBody isLoading={isLoading} ref={modalBodyRef}>
          {data?.data.map((note) => (
            <STPVipNote
              key={note.id}
              note={note}
              encounterId={patientId}
              type={type}
            />
          ))}
        </STPVipNotesModalBody>
        <Divider />
        <STPVipNotesModalFooter
          patientId={patientId}
          type={type}
          onClose={onClose}
        />
      </ModalContent>
    </Modal>
  );
}

export { STPVipNotesModal };
