import * as React from "react";

import { ChakraComponent, HTMLChakraProps, chakra } from "@chakra-ui/react";
import { FormPrompt, FormPromptWithSaveProps } from "shared/prompt";

import { useChartTrackingContext } from "contexts";
import { useFormContext } from "react-hook-form";
import { useListChartElements } from "modules/charts-shared/hooks";

/**
 * @todo - fix this type, since chakra.form css prop is incompatible
 * with this type's css property. For now it is extracted from the
 * object top avoid sending it to the form.
 */
type ChartSectionProps = {
  disabled?: boolean;
  sectionId?: string;
  onRouteChangeSave?: FormPromptWithSaveProps["onSave"];
  isPromptSaveLoading?: FormPromptWithSaveProps["isSaveLoading"];
  isDirty?: boolean;
} & HTMLChakraProps<ChakraComponent<"form">>;

function ChartSection(props: ChartSectionProps) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const {
    children,
    css: _css,
    disabled = false,
    sectionId,
    onRouteChangeSave,
    isPromptSaveLoading = false,
    isDirty,
    ...restProps
  } = props;
  const formContext = useFormContext();

  const secondaryText = React.useMemo(
    () =>
      ["SHPI", "SROS", "SPEX", "SCHC", "SASS"].includes(sectionId || "")
        ? `You must fill out every subsection
        for this section before the chart can be completed.`
        : "",
    [sectionId]
  );

  const { updateChartElements } = useChartTrackingContext();

  useListChartElements(children, updateChartElements);

  return (
    <>
      {formContext && (
        <FormPrompt
          mainText="You have unsaved changes in this section!"
          title="Warning"
          when={formContext.formState.isDirty}
          secondaryText={secondaryText}
          showSaveBtn={!!onRouteChangeSave}
          isSaveLoading={isPromptSaveLoading}
          onSave={
            onRouteChangeSave
              ? onRouteChangeSave
              : (onComplete) => {
                  onComplete();
                }
          }
        />
      )}
      <chakra.fieldset disabled={disabled}>
        <chakra.form
          padding="1.5rem"
          sx={{ "> :not(:last-child)": { marginBottom: "1rem" } }}
          height="100%"
          {...restProps}
        >
          {children}
        </chakra.form>
      </chakra.fieldset>
    </>
  );
}

export type { ChartSectionProps };

export { ChartSection };
