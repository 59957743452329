import {
  Divider,
  Modal,
  ModalContent,
  ModalOverlay,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { ChartNotesModalBody, ChartNotesModalHeader } from "modules";
import { useSharedChartNotes } from "modules/charts-shared/api";
import * as React from "react";
import { AsyncSharedChartNote } from "./AsyncSharedChartNote";
import { AsyncSharedChartNotesModalFooter } from "./AsyncSharedChartNotesModalFooter";

type SharedChartNotesModalProps = {
  title: React.ReactNode;
  encounterId: string;
  sectionCode: string;
  subsectionCode?: string;
} & Pick<UseDisclosureReturn, "isOpen" | "onClose">;

function SharedChartNotesModal(props: SharedChartNotesModalProps) {
  const { isOpen, onClose, title, encounterId, sectionCode, subsectionCode } =
    props;

  const modalBodyRef = React.useRef<HTMLDivElement>(null);

  const scrollToBottom = React.useCallback(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTo({ top: modalBodyRef.current.scrollHeight });
    }
  }, []);

  const { data, isLoading } = useSharedChartNotes({
    encounterId,
    sectionCode,
    subsectionCode,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        display="flex"
        flexDirection="column"
        height="500px"
        width="540px"
        maxWidth="540px"
        bg="white"
      >
        <ChartNotesModalHeader onClose={onClose} title={title} />
        <Divider />
        <ChartNotesModalBody isLoading={isLoading} ref={modalBodyRef}>
          {data?.data.map((note) => (
            <AsyncSharedChartNote key={note.id} note={note} />
          ))}
        </ChartNotesModalBody>
        <Divider />
        <AsyncSharedChartNotesModalFooter
          encounterId={encounterId}
          sectionCode={sectionCode}
          subsectionCode={subsectionCode}
          onCreateNoteSuccess={scrollToBottom}
        />
      </ModalContent>
    </Modal>
  );
}

export { SharedChartNotesModal };
