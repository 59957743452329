import {
  Badge,
  BadgeProps,
  Box,
  chakra,
  HStack,
  Icon,
  StackProps,
  VStack,
} from "@chakra-ui/react";
import { EmrActiveProvider, EmrProfile } from "@medstonetech/slate-icons";
import { TimerStatus } from "enums";
import { WarningTriangleFilled, MedicalStar } from "icons";
import * as React from "react";
import { EncounterAvatar, InsuranceAvatarContainer, Tooltip } from "shared";
import { TIMER_STATUS_COLOR } from "system-constants";

type ChartPatientCardProps = {
  name: string;
  age: number;
  gender: string;
  pictureUrl?: string;
  overallTimer: string;
  timerStatus: TimerStatus;
  roomNumber?: string | null;
  acuity?: number | null;
  painLevel?: number | null;
  dietType: string;
  primaryProvider?: string;
  lastProviderAssigned?: string;
  entitledToObservation?: boolean;
  encounterId: string;
  hasFoodAllergies?: boolean;
  isVip?: boolean;
  isStp?: boolean;
  chiefComplaint?: string;
} & StackProps;

type ProviderLabelProps = {
  name: string;
  isSupervisor?: boolean;
};

type ChiefComplaintLabelProps = {
  chiefComplaint?: string;
};

const BADGE_PROPS: BadgeProps = {
  height: "1.25rem",
  fontSize: "0.8125rem",
  color: "white",
  borderRadius: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0.125rem 0.5rem",
};

const ChiefComplaintLabel = ({ chiefComplaint }: ChiefComplaintLabelProps) => {
  return (
    <Tooltip label={chiefComplaint}>
      <Box color="cyan" overflow="hidden" textOverflow="ellipsis" maxW="300px">
        {chiefComplaint}
      </Box>
    </Tooltip>
  );
};

function ProviderLabel(props: ProviderLabelProps) {
  const { name, isSupervisor } = props;

  return (
    <HStack spacing="0.5rem">
      <Icon
        as={isSupervisor ? EmrProfile : EmrActiveProvider}
        color="gray.550"
        width={isSupervisor ? "0.625rem" : "1rem"}
        height="0.625rem"
      />
      <chakra.span
        color="gray.550"
        fontSize="0.75rem"
        maxWidth="170px"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        title={name}
      >
        {name}
      </chakra.span>
    </HStack>
  );
}

function ChartPatientCard(props: ChartPatientCardProps) {
  const {
    name,
    age,
    gender,
    pictureUrl,
    overallTimer,
    timerStatus,
    roomNumber,
    acuity,
    painLevel,
    primaryProvider,
    lastProviderAssigned,
    dietType,
    entitledToObservation,
    encounterId,
    hasFoodAllergies = false,
    isVip,
    isStp,
    maxWidth = "unset",
    chiefComplaint,
    ...hStackProps
  } = props;

  return (
    <HStack spacing="13px" {...hStackProps}>
      <InsuranceAvatarContainer
        avatar={
          <EncounterAvatar
            encounterId={encounterId}
            {...(isVip && { pictureIndicatorColor: "yellow" })}
            {...(isStp && { icon: <MedicalStar />, indicatorColor: "red" })}
          />
        }
        entitledToObservation={entitledToObservation || false}
        icon={
          hasFoodAllergies ? (
            <Icon as={WarningTriangleFilled} color="red" />
          ) : undefined
        }
      />
      <VStack spacing="0.25rem" w="100%" maxWidth={maxWidth}>
        <HStack spacing="10px" width="100%">
          <chakra.span
            fontSize="1.0625rem"
            fontWeight="600"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            title={name}
            textAlign="left"
            w="100%"
            maxWidth="129px"
          >
            {name}
          </chakra.span>
          <chakra.span color="gray.600" fontSize="1.125rem" fontWeight="600">
            {age} {gender && gender[0]}
          </chakra.span>
        </HStack>
        <HStack spacing="8px" width="100%" align="center">
          <Tooltip label="Total Time">
            <Badge
              {...BADGE_PROPS}
              bg={TIMER_STATUS_COLOR[timerStatus]}
              minWidth="44px"
            >
              {overallTimer}
            </Badge>
          </Tooltip>
          {roomNumber && (
            <Tooltip label="Room #">
              <Badge
                {...BADGE_PROPS}
                color="gray.600"
                borderWidth="1px"
                borderColor="gray.600"
                bg="white"
                borderRadius="0px"
                minWidth="44px"
              >
                {roomNumber}
              </Badge>
            </Tooltip>
          )}
          {acuity && (
            <Tooltip label="Acuity Level">
              <Badge
                {...BADGE_PROPS}
                bg="blue"
                width="1.25rem"
                borderRadius="3px"
              >
                {acuity}
              </Badge>
            </Tooltip>
          )}
          {painLevel && (
            <Tooltip label="Pain Level">
              <Badge bg="red" width="1.25rem" {...BADGE_PROPS}>
                {painLevel}
              </Badge>
            </Tooltip>
          )}
        </HStack>
        <HStack width="100%" spacing="36px">
          {primaryProvider && (
            <ProviderLabel name={primaryProvider} isSupervisor />
          )}
          {chiefComplaint && (
            <ChiefComplaintLabel chiefComplaint={chiefComplaint} />
          )}
        </HStack>
      </VStack>
    </HStack>
  );
}

export type { ChartPatientCardProps };
export { ChartPatientCard };
