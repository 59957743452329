import { axiosChartInstance } from "config/axios";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useDeleteChartApplicationFile(encounterId: string, chartCode: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["deleteChartApplicationFile", { encounterId, chartCode }],
    (page: number) =>
      axiosChartInstance.delete(
        `v1/chart-application/${encounterId}/${chartCode}/file`,
        { params: { pageNumber: page } }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "chartApplicationFile",
          { encounterId, chartCode },
        ]);
      },
    }
  );
}

export { useDeleteChartApplicationFile };
