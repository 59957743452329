import { useAuth0 } from "@auth0/auth0-react";
import { useToast } from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { formMessages } from "messages";
import * as React from "react";
import { Loading } from "shared";
import { extractApiErrorMessage } from "utils";
import {
  useCreateVitalCheck,
  useVitals,
  useResumeVitalTimer,
  usePauseVitalTimer,
  useResetVitalTimer,
  usePatchVitalCheck,
} from "../api";
import { BaseVitalsColumn, VitalsColumn, VitalsResponse } from "../types";
import { useUpdateIntegrationsStatus } from "modules/in-process/hooks";
import { UseFormReturn, useForm } from "react-hook-form";

type VitalsProviderProps = React.PropsWithChildren<{
  encounterId: string;
}>;

type VitalsContextValue = {
  vitalsData: VitalsResponse | null;
  isCreateLoading: boolean;
  isAddMode: boolean;
  isEditMode: boolean;
  record: VitalsColumn;
  setAdding: (isAdding: boolean) => void;
  setEditing: (isEditing: boolean) => void;
  onSave: () => void;
  setRecord: React.Dispatch<React.SetStateAction<VitalsColumn>>;
  onPause: () => void;
  onResume: () => void;
  onReset: () => void;
  isResumingOrPausing: boolean;
  isResetting: boolean;
  editVitalsForm: UseFormReturn<{ vitals: VitalsColumn[] }>;
};

const VitalsContext = React.createContext<Nullable<VitalsContextValue>>(null);

function VitalsProvider(props: VitalsProviderProps) {
  const { encounterId, children } = props;
  const { data, isLoading, error } = useVitals(encounterId);
  const [vitalsData, setVitalsData] = React.useState<Nullable<VitalsResponse>>({
    vitalChecks: [],
  });
  const [isAddMode, setIsAddMode] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const { user } = useAuth0();
  const editVitalsForm = useForm<{ vitals: VitalsColumn[] }>();
  const { mutate: patchVitalCheck } = usePatchVitalCheck({ encounterId });
  const VITALS_ADD_DEFAULT = React.useMemo(
    () => ({
      takenAt: new Date(),
      takenBy: {
        email: user?.email || "",
        fullName: `${user?.given_name} ${user?.name}` || "",
        id: user?.id || "",
        pictureUrl: user?.picture || "",
      },
      id: "",
    }),
    [user]
  );
  const [record, setRecord] = React.useState<VitalsColumn>(VITALS_ADD_DEFAULT);
  const { mutateAsync: createVitalCheck, isLoading: isCreateLoading } =
    useCreateVitalCheck(encounterId);
  const toast = useToast();
  const { mutateAsync: pauseTimer, isLoading: isPausing } =
    usePauseVitalTimer(encounterId);
  const { mutateAsync: resumeTimer, isLoading: isResuming } =
    useResumeVitalTimer(encounterId);
  const { mutateAsync: resetTimer, isLoading: isResetting } =
    useResetVitalTimer(encounterId);

  const isResumingOrPausing = isPausing || isResuming;

  const { isLoading: isLoadingIntegrationStatus, updateIntegrationStatus } =
    useUpdateIntegrationsStatus(encounterId);

  React.useEffect(() => {
    if (data?.data) {
      setVitalsData(data?.data);
    }
  }, [data]);

  const contextData: VitalsContextValue = React.useMemo(() => {
    const onSave = async () => {
      try {
        if (record === VITALS_ADD_DEFAULT) {
          toast({ description: formMessages.noChanges });
          return;
        }
        const vitalsResponse: AxiosResponse<VitalsResponse> =
          await createVitalCheck(record);

        setIsAddMode(false);
        setRecord(VITALS_ADD_DEFAULT);

        if (!isLoadingIntegrationStatus) {
          updateIntegrationStatus(vitalsResponse.data?.status ?? "Lobby");
        }
        onResume();
        toast({ description: formMessages.createSuccess("Vital check") });
      } catch (err) {
        toast({ description: extractApiErrorMessage(err) });
      }
    };

    const onPause = async () => {
      try {
        await pauseTimer({ encounterId });
      } catch (pauseError) {
        toast({ description: extractApiErrorMessage(pauseError) });
      }
    };

    const onReset = async () => {
      try {
        await resetTimer({ encounterId });
      } catch (resetError) {
        toast({ description: extractApiErrorMessage(resetError) });
      }
    };

    const onResume = async () => {
      try {
        await resumeTimer({ encounterId });
      } catch (resumeError) {
        toast({ description: extractApiErrorMessage(resumeError) });
      }
    };

    const handleEditRecords = async () => {
      const { vitals } = editVitalsForm.getValues();
      const vitalChecks = [...vitals];
      const promises = vitalChecks.map((vitalCheck) => {
        const payload = Object.entries(vitalCheck)
          .filter(
            ([path]) => path !== "id" && path !== "takenBy" && path !== "status"
          )
          .map(([path, value]) => ({
            path: path as keyof BaseVitalsColumn,
            value,
          }));
        return patchVitalCheck({
          vitalCheckId: vitalCheck.id,
          payload,
        });
      });

      try {
        await Promise.all(promises);
        toast({ description: formMessages.updateSuccess("Vital check") });
      } catch (err) {
        toast({ description: extractApiErrorMessage(err) });
      }
    };

    return {
      onSave,
      setRecord,
      vitalsData: {
        ...vitalsData,
        vitalChecks: vitalsData?.vitalChecks || [],
      },
      isCreateLoading,
      isAddMode,
      isEditMode,
      record,
      setEditing: (value: boolean) => {
        setIsEditMode(value);
        if (value) {
          editVitalsForm.reset({
            vitals: [...(vitalsData?.vitalChecks ?? [])].reverse(),
          });
        } else {
          handleEditRecords();
        }
      },
      setAdding: (value: boolean) => {
        setIsAddMode(value);
      },
      onPause,
      onReset,
      onResume,
      isResumingOrPausing,
      isResetting,
      editVitalsForm,
    };
  }, [
    vitalsData,
    isCreateLoading,
    isAddMode,
    isEditMode,
    record,
    isResumingOrPausing,
    isResetting,
    VITALS_ADD_DEFAULT,
    createVitalCheck,
    isLoadingIntegrationStatus,
    toast,
    updateIntegrationStatus,
    pauseTimer,
    encounterId,
    resetTimer,
    resumeTimer,
    editVitalsForm,
    patchVitalCheck,
  ]);

  if (error) {
    return <>Something went wrong</>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <VitalsContext.Provider value={contextData}>
      {children}
    </VitalsContext.Provider>
  );
}

function useVitalsContext() {
  const context = React.useContext(VitalsContext);

  if (!context) {
    throw new Error(
      "EncounterInfoContext value must be defined before using it"
    );
  }

  return context;
}

export { useVitalsContext, VitalsProvider };
