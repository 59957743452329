import { AxiosError } from "axios";
import { format, isValid } from "date-fns";
import { formMessages } from "messages/form";
import { useYupValidationResolver } from "hooks";
import * as React from "react";
import * as Yup from "yup";
import { useForm, UseFormReturn } from "react-hook-form";
import { Loading } from "shared";
import { dateWithoutTimezone, enumMapper } from "utils";
import {
  useEncounterInfo,
  useEncounterOverview,
  EncounterOverviewResponse,
} from "../api";
import { EncounterInfoForm } from "../types";
import { dateInputSchema } from "validators";

type EncounterInfoProviderProps = React.PropsWithChildren<{
  encounterId: string;
}>;
type EncounterInfoContextValue = {
  encounterInfoContext: UseFormReturn<EncounterInfoForm>;
  encounterOverview: {
    data?: EncounterOverviewResponse;
    error: AxiosError | null;
    isLoading: boolean;
  };
  isFormDisabled: boolean;
};
const EncounterInfoContext =
  React.createContext<Nullable<EncounterInfoContextValue>>(null);

const ENCOUNTER_INFO_FORM_INITIAL: EncounterInfoForm = {
  lastName: "",
  firstName: "",
  mi: "",
  title: null,
  maritalStatus: null,
  dob: undefined,
  ssn: "",
  gender: null,
  race: null,
  ethnicity: null,
  pictureUrl: "",
  requiredValidation: false,
  isValidated: false,
  isVip: false,
  painProtocol: false,
};

const encounterInfoValidations = {
  lastName: Yup.string()
    .nullable()
    .required(formMessages.required("Last Name")),
  firstName: Yup.string()
    .nullable()
    .required(formMessages.required("First Name")),
  mi: Yup.string().nullable(),
  title: Yup.mixed().nullable(),
  maritalStatus: Yup.mixed().nullable(),
  dob: dateInputSchema({
    errMsg: formMessages.valid("Date"),
    requiredMsg: formMessages.required("Date Of Birth"),
    maxDate: new Date(),
  }),
  ssn: Yup.string()
    .transform((o, c) => (o === "" ? null : c))
    .min(9, formMessages.atLeastNCharacters("SSN", 9))
    .nullable()
    .required(formMessages.required("SSN")),
  gender: Yup.mixed().nullable().required(formMessages.required("Gender")),
  race: Yup.mixed().nullable().required(formMessages.required("Race")),
  ethnicity: Yup.mixed()
    .nullable()
    .required(formMessages.required("Ethnicity")),
  isValidated: Yup.boolean().nullable(),
} as const;
const encounterInfoFormSchema = Yup.object().shape({
  ...encounterInfoValidations,
});

function EncounterInfoProvider(props: EncounterInfoProviderProps) {
  const { children, encounterId } = props;
  const { data: encounterInfoData, isLoading: encounterInfoIsLoading } =
    useEncounterInfo(encounterId);
  const {
    data: encounterOverviewData,
    isLoading: encounterOverviewIsLoading,
    error: encounterOverviewError,
  } = useEncounterOverview(encounterId);
  const encounterInfoResolver = useYupValidationResolver(
    encounterInfoFormSchema
  );
  const formContext = useForm<EncounterInfoForm>({
    defaultValues: ENCOUNTER_INFO_FORM_INITIAL,
    mode: "onChange",
    resolver: encounterInfoResolver,
  });
  const {
    formState: { isDirty },
    reset,
  } = formContext;

  React.useEffect(() => {
    if (encounterInfoData && !isDirty) {
      const {
        data: {
          dob,
          ethnicity,
          gender,
          maritalStatus,
          race,
          title,
          isVip,
          painProtocol,
          ...restContactInfo
        },
      } = encounterInfoData;
      const birthDate = dateWithoutTimezone(dob);
      const formattedBirthDate = format(birthDate, "MM-dd-yyyy");

      reset({
        dob: isValid(birthDate) && formattedBirthDate,
        ethnicity:
          ethnicity && enumMapper.valueToOption("ethnicity", ethnicity),
        gender: gender && enumMapper.valueToOption("gender", gender),
        maritalStatus:
          maritalStatus &&
          enumMapper.valueToOption("maritalStatus", maritalStatus),
        race: race && enumMapper.valueToOption("race", race),
        title: title && enumMapper.valueToOption("title", title),
        isVip,
        painProtocol,
        ...restContactInfo,
      });
    }
  }, [encounterInfoData, isDirty, reset]);

  const contextData: EncounterInfoContextValue = React.useMemo(() => {
    return {
      encounterInfoContext: formContext,
      encounterOverview: {
        isLoading: encounterOverviewIsLoading,
        data: encounterOverviewData?.data,
        error: encounterOverviewError,
      },
      isFormDisabled: !encounterOverviewData,
    };
  }, [
    formContext,
    encounterOverviewData,
    encounterOverviewIsLoading,
    encounterOverviewError,
  ]);

  if (encounterInfoIsLoading) {
    return <Loading />;
  }

  return (
    <EncounterInfoContext.Provider value={contextData}>
      {children}
    </EncounterInfoContext.Provider>
  );
}

function useEncounterInfoContext() {
  const context = React.useContext(EncounterInfoContext);

  if (!context) {
    throw new Error(
      "EncounterInfoContext value must be defined before using it"
    );
  }

  return context;
}

export type { EncounterInfoContextValue, EncounterInfoProviderProps };
export {
  EncounterInfoProvider,
  useEncounterInfoContext,
  encounterInfoValidations,
};
