import {
  Gender,
  InsuranceGuarantorSameAsFrom,
  InsurancePaymentType,
  InsuranceStatus,
  RelationToPatient,
  RelationToSubscriber,
  UsState,
  VerificationMethod,
} from "enums";

import { InsuranceProvidersItemResponse } from "modules/reception/api";
import { InsuranceType } from "modules/reception/types";
import { axiosPatientInstance } from "config";
import { useAxiosQuery } from "hooks";

type UseEncounterInsuranceProps = {
  insuranceType: InsuranceType;
  encounterId: string;
};

type UseEncounterInsuranceResponse = {
  provider: InsuranceProvidersItemResponse;
  pendingPayer: string;
  memberId: string;
  groupNumber: string;
  effectiveDate: Date | null;
  coverageEndDate: Date | null;
  relationToSubscriber: RelationToSubscriber;
  usePatientContactInfoAsSubscriber: boolean;
  subscriber: {
    name: string;
    phone: string;
    ssn: string;
    gender: Gender;
    dob: Date | null;
    employer: string;
    employerPhone: string;
  };
  verificationMethod: VerificationMethod;
  insuranceStatus: InsuranceStatus;
  benefitPaymentType: InsurancePaymentType;
  benefitEntitledToObservation: boolean;
  benefitPaymentAmountDue: string;
  benefitNotes: string;
  requiredValidation: boolean;
  isBenefitValidated: boolean;
  isVerificationValidated: boolean;
  benefitHasFile: boolean;
  insuranceGuarantorSameAsFrom: InsuranceGuarantorSameAsFrom | null;
  insuranceGuarantorAddress: {
    address: string;
    aptSiteNumber: string;
    city: string;
    state: UsState | null;
    zipCode: string;
  } | null;
  insuranceGuarantorInformation: {
    name: string;
    lastName: string;
    phone: string;
    dob: Date | null;
    email: string;
    relationship: RelationToPatient | null;
  } | null;
  isInsuranceInformationValidated: boolean;
  isGuarantorValidated: boolean;
  isCourtesyBenefit: boolean;
  isMSEBenefit: boolean;
};

function useEncounterInsurance(props: UseEncounterInsuranceProps) {
  const { encounterId, insuranceType } = props;

  return useAxiosQuery(["encounterInsurance", props], () =>
    axiosPatientInstance.get<UseEncounterInsuranceResponse>(
      `v1/encounter/${encounterId}/form/insurance/${insuranceType}`
    )
  );
}

export type { UseEncounterInsuranceProps, UseEncounterInsuranceResponse };
export { useEncounterInsurance };
