import { axiosChartInstance } from "config";
import { useAxiosQuery } from "hooks";

type FavoriteCommonDose = {
  id: string;
  userId: string;
  favorite: boolean;
  orderFormElementCommonDoseId: string;
};

type UseDoseFavoritesResponse = {
  favoriteOrderFormElementCommonDoses: FavoriteCommonDose[];
};

function useDoseFavorites(medicationId: string) {
  return useAxiosQuery(["doseFavorites"], () =>
    axiosChartInstance.get<UseDoseFavoritesResponse>(
      `v1/order-form/favorites/doses/${medicationId}`,
      {}
    )
  );
}
export { useDoseFavorites };
export type { UseDoseFavoritesResponse };
