import * as React from "react";

import {
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Stack,
  StackProps,
  Text,
  chakra,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  EmrCheckmarkShield,
  EmrEmployment,
  EmrHeart,
  EmrSearch,
} from "@medstonetech/slate-icons";
import { Edit, EmergencyContact, InfoRoundOutline, PillPlus } from "icons";
import {
  EncounterInfoProvider,
  PatientBasicInfoProvider,
  usePatientBasicInfoContext,
} from "modules/reception/contexts";
import { Card, CardProps, Checkbox, Loading, WarningDialog } from "shared";

import { useChartTrackingContext, usePermissions } from "contexts";
import { usePatientInfo } from "modules/charts-shared/api";
import { usePatchEncounterField } from "modules/reception/api";
import { PrimaryCarePhysicianModal } from "modules/reception/components/encounter-registration/primary-care-physician";
import { PCPEditModal } from "modules/reception/components/encounter-registration/primary-care-physician/PCPModal";
import { STPVipNotesModal } from "modules/reception/components/stp-vip-notes-modal";
import { EncounterSharedAutoSaveProps, PCP } from "modules/reception/types";
import { useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ChartType } from "types";
import { maskString } from "utils/formatter";
import { extractApiErrorMessage } from "utils/helpers";
import { SharedChartSectionHeader } from "../chart";
import { EditPharmacyModal } from "../pharmacy/AddPharmacyModal";
import { PharmacyListModal } from "../pharmacy/PharmacyList";
import { EmergencyContactEditModal } from "./EmergencyContactEditModal";
import { PatientInfoFileContainer } from "./PatientInfoFileContainer";

type PatientInfoData = {
  encounterId: string;
  gapSize?: string;
};

type ReadOnlyProps = {
  isReadOnly?: boolean;
};

type PatientInfoDataContainerProps = PatientInfoData & StackProps;

type PreferredPharmacyModalButtonProps = {
  preferredPharmacyId: string;
  encounterId: string;
} & ReadOnlyProps &
  BoxProps;

type PCPModalButtonProps = {
  encounterId?: string;
} & ReadOnlyProps &
  BoxProps;

type EditPCPModalButtonProps = { selectedPCP: PCP } & ReadOnlyProps & BoxProps;

type EditPreferedPharmacyButtonProps = PreferredPharmacyModalButtonProps &
  ReadOnlyProps;

function EditPreferedPharmacyButton(props: EditPreferedPharmacyButtonProps) {
  const { encounterId, preferredPharmacyId, ...rest } = props;
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  return (
    <Box {...rest}>
      <IconButton
        aria-label="Edit Preferred Pharmacy"
        variant="label"
        key="editBtn"
        color="blue"
        w="30px"
        h="30px"
        disabled={!preferredPharmacyId}
        _disabled={{ color: "gray.450" }}
        onClick={onEditOpen}
      >
        <Icon fontSize="1.3rem" as={Edit} />
      </IconButton>
      <EditPharmacyModal
        encounterId={encounterId}
        pharmacyId={preferredPharmacyId}
        isModalOpen={isEditOpen && !!preferredPharmacyId}
        onModalClose={onEditClose}
        isEdigintCurrentPharmacy
      />
    </Box>
  );
}

function PreferredPharmacyModalButton(
  props: PreferredPharmacyModalButtonProps
) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { encounterId, preferredPharmacyId, isReadOnly, ...rest } = props;

  return (
    <Box {...rest}>
      <IconButton
        aria-label="Select Pharmacy"
        variant="label"
        key="editBtn"
        color="blue"
        w="30px"
        h="30px"
        disabled={isReadOnly}
        id="open-pharmacy-list-btn"
        onClick={() => onOpen()}
      >
        <Icon fontSize="1.3rem" as={EmrSearch} />
      </IconButton>
      <PharmacyListModal
        isOpen={isOpen}
        onClose={onClose}
        encounterId={encounterId}
        preferredPharmacyId={preferredPharmacyId}
      />
    </Box>
  );
}

function PCPModalButton({
  encounterId,
  isReadOnly,
  ...rest
}: PCPModalButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box {...rest}>
      <IconButton
        aria-label="Select PCP"
        variant="label"
        key="editBtn"
        color="blue"
        w="30px"
        h="30px"
        id="open-pcp-btn"
        disabled={isReadOnly}
        onClick={onOpen}
      >
        <Icon fontSize="1.3rem" as={EmrSearch} />
      </IconButton>
      <PatientBasicInfoProvider encounterId={encounterId ?? ""}>
        <PrimaryCarePhysicianModal isOpen={isOpen} onClose={onClose} />
      </PatientBasicInfoProvider>
    </Box>
  );
}

function EditEmergencyContactModalButton({
  emergencyContact,
  isReadOnly,
  ...rest
}: {
  emergencyContact?: {
    firstName?: string;
    lastName?: string;
    phone?: string;
    relationship?: string;
    leaveMessage?: boolean;
  };
  isReadOnly?: boolean;
} & BoxProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Box {...rest}>
      <IconButton
        aria-label="Edit Emergency Contact"
        variant="label"
        key="editBtn"
        color="blue"
        w="30px"
        h="30px"
        id="open-emergency-contact-btn"
        disabled={isReadOnly}
        onClick={onOpen}
      >
        <Icon fontSize="1.3rem" as={Edit} />
      </IconButton>
      <EmergencyContactEditModal
        isOpen={isOpen}
        onClose={onClose}
        emergencyContact={emergencyContact}
      />
    </Box>
  );
}

function EditPCPModalButton({
  selectedPCP,
  isReadOnly,
  ...rest
}: EditPCPModalButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box {...rest}>
      <IconButton
        aria-label="Edit PCP"
        variant="label"
        key="editBtn"
        color="blue"
        w="30px"
        h="30px"
        id="open-pharmacy-list-btn"
        disabled={isReadOnly}
        onClick={onOpen}
      >
        <Icon fontSize="1.3rem" as={Edit} />
      </IconButton>
      <PCPEditModal
        isOpen={isOpen}
        onClose={onClose}
        initialPCP={selectedPCP}
        isEncounter
      />
    </Box>
  );
}

function PatientCardContainer(props: BoxProps) {
  const { children, ...rest } = props;
  return (
    <Card
      {...rest}
      bgColor="white"
      border="none"
      overflow="hidden"
      variant="default"
    >
      {children}
    </Card>
  );
}

function PatientInfoCardElement(
  props: { label: string; value: string } & BoxProps
) {
  const { label = "", value = "", ...rest } = props;
  return (
    <Box {...rest} className="child" key={label} overflow="hidden" flex="1">
      <Text
        color="gray.550"
        fontSize="0.9375rem"
        lineHeight="1.11875rem"
        marginBottom="0.5rem"
        fontWeight="500"
      >
        {label}
      </Text>
      <Text fontSize="1.0625rem" lineHeight="1.268125rem" fontWeight="500">
        {value}
      </Text>
    </Box>
  );
}

function PatientInfoCardRow(props: StackProps) {
  const { children, ...rest } = props;
  return (
    <Stack
      {...rest}
      direction={["column", "column", "row"]}
      spacing={{ base: "0.5rem", md: "1.125rem" }}
    >
      {children}
    </Stack>
  );
}

function PatientInfoCard(
  props: {
    headerGradient?: string;
    headerIconBgColor?: string;
    iconHeader: React.ReactElement;
    headerElements?: React.ReactElement[];
  } & BoxProps
) {
  const {
    children,
    title,
    iconHeader,
    headerGradient = "gray",
    headerIconBgColor,
    headerElements,
    ...rest
  } = props;
  return (
    <PatientCardContainer {...rest}>
      <Flex
        id="header"
        bgGradient={headerGradient}
        padding="10px 20px"
        alignItems="center"
      >
        {iconHeader && (
          <Flex
            marginRight="15px"
            w="30px"
            h="30px"
            borderRadius="5px"
            justifyContent="center"
            alignItems="center"
            bgColor={headerIconBgColor}
          >
            {React.cloneElement(iconHeader, {
              color: "white",
              height: "20px",
              width: "20px",
            })}
          </Flex>
        )}
        <chakra.span
          color="black"
          fontSize="1.0625rem"
          lineHeight="1.268125rem"
          fontWeight="600"
          letterSpacing="0.01em"
          flex="1"
        >
          {title}
        </chakra.span>
        <Flex columnGap="0.5rem">
          {headerElements?.map((element) => element)}
        </Flex>
      </Flex>
      <Divider
        color="gray.450"
        width="calc(100% - 65px)"
        ml="65px"
        height="0px"
      />
      <Box padding="20px" className="container">
        <Stack
          direction={["column"]}
          spacing={{ base: "0.5rem", md: "1.125rem" }}
        >
          {children}
        </Stack>
      </Box>
    </PatientCardContainer>
  );
}

type GetAutoSaveProps<T> = (
  fieldName: keyof T
) => EncounterSharedAutoSaveProps<T>;

type PainProtocolPatientProps = {
  encounterId: string;
} & ReadOnlyProps &
  CardProps;

function PainProtocolPatient(props: PainProtocolPatientProps) {
  const { encounterId, isReadOnly, ...restProps } = props;
  const { scope } = usePermissions();

  const {
    painProtocolPatientFormContext: { control, setValue },
  } = usePatientBasicInfoContext();

  const isPainProtocolActive = useWatch({ control, name: "painProtocol" });

  const { mutateAsync, isLoading } = usePatchEncounterField({
    encounterId,
  });

  const { onOpen: onOpenStpNotes, isOpen, onClose } = useDisclosure();

  const uncheckWarningDisclose = useDisclosure();

  const toast = useToast();

  const onCheckStp = () => {
    if (isPainProtocolActive) {
      uncheckWarningDisclose.onOpen();
      return;
    }

    toast({
      description:
        "You won't be able to mark this patient as STP unless you add a note",
    });

    onOpenStpNotes();
  };

  const onUncheck = async () => {
    try {
      await mutateAsync([{ path: "painProtocol", value: false }]);
      setValue("painProtocol", false);
      uncheckWarningDisclose.onClose();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  return (
    <Card
      variant="default"
      padding="20px"
      display="flex"
      flexDir="row"
      alignItems="center"
      gap="1rem"
      {...restProps}
    >
      <Checkbox
        color="gray.650"
        onChangeCapture={onCheckStp}
        isChecked={isPainProtocolActive}
        isDisabled={
          isReadOnly ||
          (!scope("inprocess:stp").isEditable &&
            (isPainProtocolActive || isPainProtocolActive === undefined))
        }
      >
        STP
      </Checkbox>

      <React.Fragment>
        <Button
          variant="outline"
          h="fit-content"
          p="0.25rem 1.5rem"
          minW="0"
          borderColor={isPainProtocolActive ? "blue" : "gray"}
          color={isPainProtocolActive ? "blue" : "gray"}
          disabled={isReadOnly}
          onClick={onOpenStpNotes}
        >
          STP Notes
        </Button>

        {isOpen && (
          <STPVipNotesModal
            title="Stp Notes"
            patientId={encounterId}
            isOpen={isOpen}
            onClose={onClose}
            type="Stp"
          />
        )}

        {uncheckWarningDisclose.isOpen && (
          <WarningDialog
            isOpen={uncheckWarningDisclose.isOpen}
            onCancel={uncheckWarningDisclose.onClose}
            title="Warning!"
            mainText="Are you sure you want to unmark this Patient as STP?"
            onClose={uncheckWarningDisclose.onClose}
            onAction={onUncheck}
            cancelLabel="Cancel"
            actionLabel="Unmark"
            blockScrollOnMount={false}
            cancelButtonProps={{ color: "red" }}
            actionButtonProps={{ color: "blue" }}
            actionsDirection="vertical"
            isActionLoading={isLoading}
          />
        )}
      </React.Fragment>
    </Card>
  );
}

type VIPPatientProps = {
  encounterId: string;
} & ReadOnlyProps &
  CardProps;

function VIPPatient(props: VIPPatientProps) {
  const { encounterId, isReadOnly, ...restProps } = props;
  const { scope } = usePermissions();

  const {
    vipPatientFormContext: { control, setValue },
  } = usePatientBasicInfoContext();

  const isVipActive = useWatch({ control, name: "isVip" });

  const { mutateAsync, isLoading } = usePatchEncounterField({
    encounterId,
  });

  const { onOpen: onOpenStpNotes, isOpen, onClose } = useDisclosure();

  const toast = useToast();

  const uncheckWarningDisclose = useDisclosure();

  const onCheckVip = () => {
    if (isVipActive) {
      uncheckWarningDisclose.onOpen();
      return;
    }

    toast({
      description:
        "You won't be able to mark this patient as VIP unless you add a note",
    });

    onOpenStpNotes();
  };

  const onUncheck = async () => {
    try {
      await mutateAsync([{ path: "isVip", value: false }]);
      setValue("isVip", false);
      uncheckWarningDisclose.onClose();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  return (
    <Card
      variant="default"
      padding="20px"
      display="flex"
      flexDir="row"
      alignItems="center"
      gap="1rem"
      {...restProps}
    >
      <Checkbox
        color="gray.650"
        onChangeCapture={onCheckVip}
        isChecked={isVipActive}
        isDisabled={
          isReadOnly ||
          (!scope("inprocess:vip").isEditable &&
            (isVipActive || isVipActive === undefined))
        }
      >
        VIP
      </Checkbox>

      <React.Fragment>
        <Button
          variant="outline"
          h="fit-content"
          p="0.25rem 1.5rem"
          minW="0"
          onClick={onOpenStpNotes}
          borderColor={isVipActive ? "blue" : "gray"}
          color={isVipActive ? "blue" : "gray"}
          disabled={isReadOnly}
        >
          VIP Notes
        </Button>

        {isOpen && (
          <STPVipNotesModal
            title="VIP"
            patientId={encounterId}
            isOpen={isOpen}
            onClose={onClose}
            type="Vip"
          />
        )}

        {uncheckWarningDisclose.isOpen && (
          <WarningDialog
            isOpen={uncheckWarningDisclose.isOpen}
            onCancel={uncheckWarningDisclose.onClose}
            title="Warning!"
            mainText="Are you sure you want to unmark this Patient as VIP?"
            onClose={uncheckWarningDisclose.onClose}
            onAction={onUncheck}
            cancelLabel="Cancel"
            actionLabel="Unmark"
            blockScrollOnMount={false}
            cancelButtonProps={{ color: "red" }}
            actionButtonProps={{ color: "blue" }}
            actionsDirection="vertical"
            isActionLoading={isLoading}
          />
        )}
      </React.Fragment>
    </Card>
  );
}

function PatientInfoDataContainer(props: PatientInfoDataContainerProps) {
  const { encounterId, gapSize, ...rest } = props;
  const toast = useToast();
  const { isPastEncounter } = useChartTrackingContext();
  const { data, isLoading, error } = usePatientInfo(encounterId);

  React.useEffect(() => {
    if (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  }, [error, toast]);

  return (
    <Stack direction={{ base: "column" }} flex="1" spacing={gapSize} {...rest}>
      {isLoading ? (
        <Loading minHeight="200px" />
      ) : (
        <Stack
          direction={{ base: "column" }}
          flex="1"
          spacing={gapSize}
          {...rest}
        >
          <PatientInfoCard
            title="Contact Info"
            iconHeader={<Icon as={InfoRoundOutline} />}
            headerIconBgColor="green"
          >
            <PatientInfoCardRow>
              <PatientInfoCardElement
                label="Phone"
                value={data?.data?.contactInfo?.phone || ""}
              />
              <PatientInfoCardElement
                label="Email"
                value={data?.data?.contactInfo?.email || ""}
              />
            </PatientInfoCardRow>
            <PatientInfoCardRow>
              <PatientInfoCardElement
                label="Street"
                value={data?.data?.contactInfo?.street || ""}
              />
              <PatientInfoCardElement
                label="City"
                value={data?.data?.contactInfo?.city || ""}
              />
            </PatientInfoCardRow>
          </PatientInfoCard>
          <Stack
            direction={{
              base: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            spacing={gapSize}
          >
            <PatientInfoCard
              title="Employment"
              iconHeader={<Icon as={EmrEmployment} mr="0" />}
              headerIconBgColor="blue"
              flex={{
                base: "initial",
                lg: "1",
                xl: "initial",
                "2xl": "1",
              }}
            >
              <PatientInfoCardRow>
                <PatientInfoCardElement
                  label="Employer"
                  value={data?.data?.employment?.name || ""}
                />
                <PatientInfoCardElement
                  label="Employer Phone"
                  value={data?.data?.employment?.employerPhone || ""}
                />
              </PatientInfoCardRow>
              <PatientInfoCardRow>
                <PatientInfoCardElement
                  label="Occupation"
                  value={data?.data?.employment?.occupation || ""}
                />
                <PatientInfoCardElement
                  label="Ext."
                  value={data?.data?.employment?.ext || ""}
                />
              </PatientInfoCardRow>
            </PatientInfoCard>
            <PatientInfoCard
              title="Preferred Pharmacy"
              iconHeader={<Icon as={PillPlus} />}
              headerIconBgColor="orange"
              headerElements={[
                <PreferredPharmacyModalButton
                  encounterId={encounterId}
                  preferredPharmacyId={data?.data?.preferredPharmacy?.id || ""}
                  key="prefPharma"
                  isReadOnly={isPastEncounter}
                />,
                <EditPreferedPharmacyButton
                  encounterId={encounterId}
                  key="editPrefPharma"
                  preferredPharmacyId={data?.data?.preferredPharmacy?.id || ""}
                  isReadOnly={isPastEncounter}
                />,
              ]}
              flex={{
                base: "initial",
                lg: "1",
                xl: "initial",
                "2xl": "1",
              }}
            >
              <PatientInfoCardRow>
                <PatientInfoCardElement
                  label="Name (Street)"
                  value={data?.data?.preferredPharmacy?.name || ""}
                />
              </PatientInfoCardRow>
              <PatientInfoCardRow>
                <PatientInfoCardElement
                  label="Phone"
                  value={data?.data?.preferredPharmacy?.phone || ""}
                />
                <PatientInfoCardElement
                  label="NCPDPID"
                  value={data?.data?.preferredPharmacy?.ncpdpId || ""}
                />
              </PatientInfoCardRow>
            </PatientInfoCard>
          </Stack>
          <Stack
            direction={{
              base: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            spacing={gapSize}
          >
            <Box
              flex={{
                base: "initial",
                lg: "1",
                xl: "initial",
                "2xl": "1",
              }}
            >
              <PatientInfoCard
                title="Insurance"
                iconHeader={<Icon as={EmrCheckmarkShield} />}
                headerIconBgColor="indigo"
              >
                <PatientInfoCardRow>
                  <PatientInfoCardElement
                    label="Name"
                    value={data?.data?.insurance?.name || ""}
                  />
                </PatientInfoCardRow>
              </PatientInfoCard>
              <HStack pt={4} width="100%" justifyContent="space-between">
                <EncounterInfoProvider encounterId={encounterId}>
                  <PatientBasicInfoProvider encounterId={encounterId}>
                    <PainProtocolPatient
                      encounterId={encounterId}
                      isReadOnly={isPastEncounter}
                      width="100%"
                    />
                    <VIPPatient
                      encounterId={encounterId}
                      isReadOnly={isPastEncounter}
                      width="100%"
                    />
                  </PatientBasicInfoProvider>
                </EncounterInfoProvider>
              </HStack>
            </Box>

            <PatientInfoCard
              title="Primary Care Physician"
              iconHeader={<Icon as={EmrHeart} />}
              headerIconBgColor="pink"
              headerElements={[
                <PCPModalButton
                  encounterId={encounterId}
                  isReadOnly={isPastEncounter}
                />,
                <EditPCPModalButton
                  selectedPCP={data?.data?.primaryCarePhysician as PCP}
                  isReadOnly={isPastEncounter}
                />,
              ]}
              flex={{
                base: "initial",
                lg: "1",
                xl: "initial",
                "2xl": "1",
              }}
            >
              <PatientInfoCardRow>
                <PatientInfoCardElement
                  label="Name"
                  value={data?.data?.primaryCarePhysician?.name || ""}
                />
              </PatientInfoCardRow>
              <PatientInfoCardRow>
                <PatientInfoCardElement
                  label="Phone"
                  value={data?.data?.primaryCarePhysician?.phone || ""}
                />
                <PatientInfoCardElement
                  label="Fax"
                  value={maskString(
                    data?.data?.primaryCarePhysician?.fax || "",
                    "(###) ###-####"
                  )}
                />
              </PatientInfoCardRow>
            </PatientInfoCard>
          </Stack>
          <PatientInfoCard
            title="Emergency Contact"
            iconHeader={<Icon as={EmergencyContact} />}
            headerIconBgColor="red"
            headerElements={[
              <EditEmergencyContactModalButton
                emergencyContact={data?.data.emergencyContact}
                isReadOnly={isPastEncounter}
              />,
            ]}
          >
            <PatientInfoCardRow>
              <PatientInfoCardElement
                label="Name"
                value={
                  `${data?.data?.emergencyContact?.firstName ?? ""} ${
                    data?.data?.emergencyContact?.lastName ?? ""
                  }` || ""
                }
              />
              <PatientInfoCardElement
                label="Relation to Patient"
                value={data?.data?.emergencyContact?.relationship || ""}
              />
            </PatientInfoCardRow>
            <PatientInfoCardRow>
              <PatientInfoCardElement
                label="Phone"
                value={maskString(
                  data?.data?.emergencyContact?.phone || "",
                  "(###) ###-####"
                )}
              />
              <PatientInfoCardElement
                label="Leave Message"
                value={
                  data?.data?.emergencyContact?.leaveMessage ? "Yes" : "No"
                }
              />
            </PatientInfoCardRow>
          </PatientInfoCard>
        </Stack>
      )}
    </Stack>
  );
}

function PatientInfo(props: { chartType?: ChartType } & BoxProps) {
  const gapSize = "20px";
  const { encounterId = "" } = useParams<{ encounterId: string }>();
  const { chartType, ...rest } = props;

  return (
    <Box {...rest} height="100vh" overflow="auto" padding="20px">
      <SharedChartSectionHeader
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.1);"
        border-radius="10px"
        marginBottom={gapSize}
        icon={<Icon color="white" bgColor="blue" as={InfoRoundOutline} />}
        encounterId={encounterId}
        sectionId="SPIF"
        showNotesBtn={chartType === "Triage" ? false : undefined}
        showInformationSentButtons
      >
        Patient Info
      </SharedChartSectionHeader>
      <Stack direction={{ base: "column", xl: "row" }} spacing={gapSize}>
        <PatientInfoDataContainer
          encounterId={encounterId}
          direction={{ base: "column" }}
          gapSize={gapSize}
          flex="1"
        />
        <PatientInfoFileContainer
          encounterId={encounterId}
          flex={{ xl: "0.8" }}
          overflow="hidden"
        />
      </Stack>
    </Box>
  );
}

export {
  PatientCardContainer,
  PatientInfo,
  PatientInfoCard,
  PatientInfoCardElement,
  PatientInfoCardRow,
};

export type { GetAutoSaveProps };
