import * as React from "react";

import { FormPrompt, FormPromptWithSaveProps } from "shared/prompt";
import { HTMLChakraProps, chakra } from "@chakra-ui/react";

import { useChartTrackingContext } from "contexts";
import { useListChartElements } from "modules/charts-shared/hooks";

type ChartFormProps = {
  onRouteChangeSave: FormPromptWithSaveProps["onSave"];
  isPromptSaveLoading: FormPromptWithSaveProps["isSaveLoading"];
  isDirty: boolean;
  disabled?: boolean;
  sectionId?: string;
  setChartElementsList?(chartElementList: Record<string, string>[]): void;
} & React.ComponentProps<"form"> &
  HTMLChakraProps<"form">;

function ChartForm(props: ChartFormProps) {
  const {
    onRouteChangeSave,
    isDirty,
    isPromptSaveLoading,
    disabled = false,
    sectionId,
    children,
    ...formProps
  } = props;

  const secondaryText = React.useMemo(
    () =>
      ["SHPI", "SROS", "SPEX", "SCHC", "SASS"].includes(sectionId || "")
        ? `You must fill out every subsection
        for this section before the chart can be completed.`
        : "",
    [sectionId]
  );

  const { updateChartElements } = useChartTrackingContext();

  useListChartElements(children, updateChartElements);

  return (
    <>
      <FormPrompt
        mainText="You have unsaved changes in this section!"
        onSave={onRouteChangeSave}
        title="Warning"
        when={isDirty}
        isSaveLoading={isPromptSaveLoading}
        secondaryText={secondaryText}
      />
      <chakra.fieldset disabled={disabled}>
        <chakra.form
          padding="1.5rem"
          sx={{ "> :not(:last-child)": { marginBottom: "1rem" } }}
          height="100%"
          children={children}
          {...formProps}
        />
      </chakra.fieldset>
    </>
  );
}

export { ChartForm };
