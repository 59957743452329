import { Box, BoxProps } from "@chakra-ui/react";
import { OrderStatus } from "enums";
import * as React from "react";
import { enumMapper } from "utils";
import { ORDER_STATUS_COLOR } from "../constants";

type OrderStatusPillProps = Omit<BoxProps, "children"> & {
  status: OrderStatus;
};

function OrderStatusPill(props: OrderStatusPillProps) {
  const { status, ...restProps } = props;

  return (
    <Box
      padding="6px 0"
      borderRadius="40px"
      bg={ORDER_STATUS_COLOR[status]}
      fontSize="1.0625rem"
      lineHeight="1.25rem"
      fontWeight="500"
      color="white"
      width="120px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...restProps}
    >
      {enumMapper.toDisplay("orderStatus", status)}
    </Box>
  );
}

export type { OrderStatusPillProps };
export { OrderStatusPill };
