import { axiosChartInstance } from "config/axios";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

function useUploadChartApplicationFile(encounterId: string, chartCode: string) {
  const queryClient = useQueryClient();

  return useAxiosMutation(
    ["uploadChartApplicationFile", { encounterId, chartCode }],
    (file: FormData) =>
      axiosChartInstance.post(
        `v1/chart-application/${encounterId}/${chartCode}/file`,
        file
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "chartApplicationFile",
          { encounterId, chartCode },
        ]);
      },
    }
  );
}

export { useUploadChartApplicationFile };
