import { format } from "date-fns";
import { OrderGroup } from "enums";

function getOrderDetailsDateFormat(date?: Date | null) {
  if (!date) {
    return "";
  }

  return `${format(date, "MMM dd, yyyy")} at \n ${format(date, "h:mm a")}`;
}

function getOrderDetailsDateShortFormat(date?: Date | null) {
  if (!date) {
    return "";
  }

  return format(date, "MM/dd/yy h:mm a");
}

function getValueByOrderGroup<T>(
  labsValue: T,
  radiologyValue: T
): Record<OrderGroup, T> {
  return {
    Labs: labsValue,
    Radiology: radiologyValue,
  };
}

export {
  getOrderDetailsDateFormat,
  getOrderDetailsDateShortFormat,
  getValueByOrderGroup,
};
