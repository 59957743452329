import { Icon } from "@chakra-ui/react";
import { EmrInProcess, EmrRegistration } from "@medstonetech/slate-icons";
import { OrderStatus } from "enums";
import { CloseCircle, ExclamationSquare, SquaredChecked } from "icons";

const ORDER_STATUS_COLOR: Record<OrderStatus, string> = {
  Cancelled: "red",
  Completed: "green",
  InProcess: "blue",
  Ordered: "orange",
  NeedsApproval: "yellow",
};

const ORDER_STATUS_ICON: Record<OrderStatus, JSX.Element> = {
  Cancelled: <Icon as={CloseCircle} />,
  Completed: <Icon as={SquaredChecked} />,
  InProcess: <Icon as={EmrInProcess} />,
  Ordered: <Icon as={EmrRegistration} />,
  NeedsApproval: <Icon as={ExclamationSquare} />,
};

export { ORDER_STATUS_COLOR, ORDER_STATUS_ICON };
