import { RadioGroupProps, useRadioGroup, HStack } from "@chakra-ui/react";
import { config } from "config";
import * as React from "react";
import { SelectOption } from "types";
import { RadioChartOption } from "./RadioChartOption";

type RadioChartOptionGroupProps = Omit<RadioGroupProps, "children"> & {
  options: SelectOption<string | number>[];
};

const RadioChartOptionGroup = React.forwardRef<
  HTMLDivElement,
  RadioChartOptionGroupProps
>((props, ref) => {
  const { options, ...rest } = props;
  const { getRootProps, getRadioProps } = useRadioGroup(rest);

  const group = getRootProps();

  return (
    <HStack ref={ref} {...group}>
      {options.map(({ label, value }) => {
        const radio = getRadioProps({ value });
        return (
          <RadioChartOption key={value} {...radio}>
            {label}
          </RadioChartOption>
        );
      })}
    </HStack>
  );
});

if (config.isDev) {
  RadioChartOptionGroup.displayName = "RadioChartOptionGroup";
}

export type { RadioChartOptionGroupProps };
export { RadioChartOptionGroup };
