import {
  Button,
  chakra,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";
import * as yup from "yup";
import { ButtonProps, Textarea, ToolbarHeader, WarningDialog } from "shared";
import { ReasonSelector, ReasonSelectorItem } from "./ReasonSelector";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useYupValidationResolver } from "hooks";
import { useCancelOrder } from "modules/orders/api";
import { formMessages, genericErrors } from "messages";
import { CancellationReason } from "modules/orders/enums";

type CancelOrderModalProps = {
  onCancelSuccess?: () => void;
  isModalOpen: boolean;
  onModalClose: () => void;
  orderId: string;
  encounterId: string;
};

type ButtonCancelOrderModalProps = {
  onCancelSuccess?: () => void;
  orderId: string;
  encounterId: string;
} & Omit<ButtonProps, "onClick">;

const schema = yup
  .object({
    reason: yup.string().required("Please select at least one option"),
    other: yup.string(),
  })
  .test(
    "noCustomReason",
    "Please type a valid reason ",
    (values) =>
      values.reason !== "Other" || (values.reason === "Other" && !!values.other)
  );

function ButtonCancelOrderModal(props: ButtonCancelOrderModalProps) {
  const { onCancelSuccess, orderId, encounterId, ...rest } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <Button
        variant="outlineSquared"
        colorScheme="red"
        onClick={onOpen}
        {...rest}
      >
        Cancel Order
      </Button>
      <CancelOrderModal
        isModalOpen={isOpen}
        onModalClose={onClose}
        orderId={orderId}
        encounterId={encounterId}
        onCancelSuccess={onCancelSuccess}
      />
    </>
  );
}

function CancelOrderModalContent(props: CancelOrderModalProps) {
  const { onModalClose, onCancelSuccess, orderId, encounterId } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();

  const {
    control,
    register,
    setValue,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    resolver: useYupValidationResolver(schema),
    mode: "onChange",
    defaultValues: {
      reason: "",
      other: "",
    },
  });

  const { mutateAsync: cancelOrder, isLoading } = useCancelOrder(
    orderId,
    encounterId
  );

  const onSubmit: SubmitHandler<{
    reason: CancellationReason;
    other: string;
  }> = async ({ reason, other }) => {
    try {
      onClose();
      const payload = {
        reason,
        other: reason === "Other" ? other : null,
      };
      await cancelOrder(payload);
      toast({
        status: "success",
        description: formMessages.updateSuccess("Order"),
      });
      onModalClose();
      onCancelSuccess?.();
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast({
          status: "error",
          description: e.message || genericErrors.unknownError,
        });
      }
    }
  };

  return (
    <>
      <WarningDialog
        isOpen={isOpen}
        onCancel={onClose}
        title="Warning!"
        mainText="Are you sure you want to cancel this order? This action cannot be undone."
        onClose={onClose}
        onAction={handleSubmit(onSubmit)}
        cancelLabel="Go Back"
        actionLabel="Cancel Order"
        actionsDirection="vertical"
        blockScrollOnMount={false}
      />
      <ModalHeader>
        <ToolbarHeader
          titleText={
            <chakra.span fontSize="1.0625rem" fontWeight="600" color="black">
              Reason Canceled
            </chakra.span>
          }
          leftButtons={[
            <Button onClick={onModalClose} key="cancelBtn">
              Cancel
            </Button>,
          ]}
          rightButtons={[
            <Button
              key="doneBtn"
              onClick={onOpen}
              isDisabled={!isValid}
              isLoading={isLoading}
            >
              Done
            </Button>,
          ]}
        />
      </ModalHeader>
      <ModalBody p="0 15px 19px">
        <VStack
          justifyContent="center"
          spacing="0px"
          my="45px"
          fontSize="1.0625rem"
          fontWeight="500"
          color="gray.600"
        >
          <chakra.span width="100%" textAlign="center">
            You must add a reason
          </chakra.span>
          <chakra.span width="100%" textAlign="center">
            why you are cancelling this order.
          </chakra.span>
        </VStack>
        <form>
          <Controller
            name="reason"
            control={control}
            render={({ field }) => (
              <ReasonSelector mb="25px" {...field}>
                <ReasonSelectorItem value="Mistake" height="60px">
                  <chakra.span fontWeight="500" fontSize="1.0625rem">
                    Ordered by mistake.
                  </chakra.span>
                </ReasonSelectorItem>
                <ReasonSelectorItem value="Duplicate" height="60px">
                  <chakra.span fontWeight="500" fontSize="1.0625rem">
                    Duplicate Order.
                  </chakra.span>
                </ReasonSelectorItem>
                <ReasonSelectorItem value="Provider" height="60px">
                  <chakra.span fontWeight="500" fontSize="1.0625rem">
                    Canceled by Dr’s request.
                  </chakra.span>
                </ReasonSelectorItem>
                <chakra.span
                  pl="10px"
                  width="100%"
                  textAlign="start"
                  fontSize="0.9375rem"
                  fontWeight="500"
                  color="gray.700"
                >
                  Other reason:
                </chakra.span>
                <ReasonSelectorItem value="Other">
                  <Textarea
                    onFocus={() => {
                      setValue("reason", "Other");
                    }}
                    bg="white"
                    borderWidth="0px"
                    w="100%"
                    minH="180px"
                    fontSize="1.0625rem"
                    placeholder="Type reason here"
                    {...register("other")}
                  />
                </ReasonSelectorItem>
              </ReasonSelector>
            )}
          />
        </form>
      </ModalBody>
    </>
  );
}

function CancelOrderModal(props: CancelOrderModalProps) {
  const { onCancelSuccess, isModalOpen, onModalClose, orderId, encounterId } =
    props;

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onModalClose}
      isCentered
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent bg="gray.200" maxWidth="540px">
        {isModalOpen && (
          <CancelOrderModalContent
            isModalOpen={isModalOpen}
            onModalClose={onModalClose}
            orderId={orderId}
            encounterId={encounterId}
            onCancelSuccess={onCancelSuccess}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

export { CancelOrderModal, ButtonCancelOrderModal };
