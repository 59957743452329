const required = (field: string) => `${field} is a required field`;
const createSuccess = (entity: string) => `${entity} created successfully`;
const updateSuccess = (entity: string) => `${entity} updated successfully`;
const deleteSuccess = (entity: string) => `${entity} deleted successfully`;
const valid = (field: string) => `${field} value is not valid`;
const validPhone = (field: string) =>
  `${field} value is not a valid phone number`;
const noChanges = "No changes detected";
const unsavedChanges =
  "Are you sure you want to leave? Latest changes will be lost.";
const atLeastNCharacters = (field: string, length: number) =>
  `The ${field} requires at least ${length} characters`;
const oneOf = (field: string, options: string[]) =>
  `${field} must be one of the following values: ${options.join(", ")}`;

export {
  required,
  createSuccess,
  updateSuccess,
  deleteSuccess,
  valid,
  noChanges,
  validPhone,
  unsavedChanges,
  atLeastNCharacters,
  oneOf,
};
