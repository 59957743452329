import {
  Box,
  BoxProps,
  Flex,
  StackProps,
  useMergeRefs,
  useRadio,
  useRadioGroup,
  UseRadioGroupProps,
  VStack,
} from "@chakra-ui/react";
import { config } from "config";
import * as React from "react";

type ReasonSelectorProps = {
  isReadOnly?: boolean;
} & UseRadioGroupProps &
  Omit<StackProps, keyof UseRadioGroupProps>;

type ReasonSelectorContextValue = {
  getRadioProps: ReturnType<typeof useRadioGroup>["getRadioProps"];
  isDisabled?: boolean;
  isReadOnly?: boolean;
};

const ReasonSelectorContext =
  React.createContext<ReasonSelectorContextValue | null>(null);

function useReasonSelectorContext() {
  const context = React.useContext(ReasonSelectorContext);

  if (!context) {
    throw new Error(
      "Consumers of this context must be children of a Reason Selector component."
    );
  }

  return context;
}

type ReasonSelectorItemProps = {
  preventDefault?: boolean;
  value: string | number;
} & BoxProps;

function ReasonSelectorItem(props: ReasonSelectorItemProps) {
  const { value, preventDefault = true, children, ...boxProps } = props;
  const { getRadioProps, isDisabled, isReadOnly } = useReasonSelectorContext();
  const radioProps = getRadioProps({ value });
  const { getInputProps, getCheckboxProps } = useRadio(radioProps);

  const input = getInputProps();
  const { onMouseDown, ...restCheckbox } = getCheckboxProps();

  const stateStylesProps = React.useMemo(
    () => ({
      ...(isReadOnly || isDisabled
        ? {}
        : { _focus: { boxShadow: "var(--chakra-shadows-outline)" } }),
    }),
    [isDisabled, isReadOnly]
  );

  return (
    <Box as="label" width="100%">
      <input {...input} disabled={isDisabled} readOnly={isReadOnly} />
      <Flex
        {...restCheckbox}
        {...(preventDefault && { onMouseDown })}
        aria-disabled={isDisabled}
        flexDirection="column"
        bg="white"
        width="100%"
        borderRadius="10px"
        justifyContent="center"
        alignItems="center"
        color="gray.700"
        transition="all 200ms"
        borderWidth="1px"
        borderColor="gray.450"
        _checked={{ borderColor: "blue", color: "blue" }}
        _disabled={{ opacity: 0.6 }}
        {...stateStylesProps}
        {...boxProps}
      >
        {children}
      </Flex>
    </Box>
  );
}

const ReasonSelector = React.forwardRef<HTMLDivElement, ReasonSelectorProps>(
  (props, ref) => {
    const {
      value,
      defaultValue,
      onChange,
      isDisabled,
      isReadOnly,
      isFocusable,
      name,
      isNative,
      ...stackProps
    } = props;
    const { getRadioProps, getRootProps } = useRadioGroup({
      value,
      defaultValue,
      onChange,
      isDisabled,
      isFocusable,
      name,
      isNative,
    });

    const { ref: groupRef, ...rest } = getRootProps();
    const mergedRefs = useMergeRefs(ref, groupRef);

    const contextValue: ReasonSelectorContextValue = React.useMemo(
      () => ({ getRadioProps, isDisabled, isReadOnly }),
      [getRadioProps, isDisabled, isReadOnly]
    );

    return (
      <ReasonSelectorContext.Provider value={contextValue}>
        <VStack ref={mergedRefs} spacing="10px" {...stackProps} {...rest} />
      </ReasonSelectorContext.Provider>
    );
  }
);

if (config.isDev) {
  ReasonSelector.displayName = "ReasonSelector";
}

export type { ReasonSelectorProps };
export { ReasonSelector, ReasonSelectorItem };
