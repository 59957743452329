import * as React from "react";
import { ChartNotesModalFooter } from "modules";
import {
  useCreateSharedChartNote,
  useCreateSharedChartNoteAudio,
} from "modules/charts-shared/api";

type AsyncSharedChartNotesModalFooterProps = {
  encounterId: string;
  sectionCode: string;
  subsectionCode?: string;
  onCreateNoteSuccess?: () => void;
};

function AsyncSharedChartNotesModalFooter(
  props: AsyncSharedChartNotesModalFooterProps
) {
  const { encounterId, sectionCode, subsectionCode, onCreateNoteSuccess } =
    props;
  const { mutateAsync: createNote, isLoading: isCreateNoteLoading } =
    useCreateSharedChartNote({
      encounterId,
      sectionCode,
      subsectionCode,
    });
  const { mutateAsync: createAudioNote, isLoading: isCreateAudioNoteLoading } =
    useCreateSharedChartNoteAudio({
      encounterId,
      sectionCode,
      subsectionCode,
    });
  const isLoading = isCreateNoteLoading || isCreateAudioNoteLoading;

  return (
    <ChartNotesModalFooter
      isLoading={isLoading}
      onCreateAudioNote={createAudioNote}
      onCreateNote={createNote}
      onCreateNoteSuccess={onCreateNoteSuccess}
    />
  );
}

export { AsyncSharedChartNotesModalFooter };
