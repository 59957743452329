import { Box, Icon, VStack } from "@chakra-ui/react";
import { ProgressNoteBy } from "enums/progress-noteBy";
import { useToast } from "hooks";
import { Chart } from "icons";
import { formMessages } from "messages";
import { ChartRouteBaseParams } from "modules";
import {
  SharedChartSectionHeader,
  SharedSectionBaseProps,
  useSaveAndNext,
} from "modules/charts-shared";
import { ChartForm } from "modules/charts-shared-v2";
import {
  useCreateProgressNote,
  useProgressNotes,
  useUpdateProgressNotes,
} from "modules/charts-shared/api";
import * as React from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { MdOutlineSwapVerticalCircle } from "react-icons/md";
import { useLocation, useParams } from "react-router-dom";
import { Button, Loading } from "shared";
import { FormPromptWithSaveProps } from "shared/prompt";
import { ChartProgressItem } from "types";
import { extractApiErrorMessage } from "utils";
import { ProgressCard, ProgressCardHeader } from "./ProgressCard";
import { ProgressNoteType } from "enums";
import { useChartTrackingContext } from "contexts";

type ProgressForm = {
  items: Omit<ChartProgressItem, "encounterId">[];
};

function Progress(props: SharedSectionBaseProps) {
  const { chartType, isReadOnly = false } = props;
  const { encounterId = "" } = useParams<ChartRouteBaseParams>();
  const { pathname } = useLocation();
  const noteBy = pathname.includes("triage")
    ? ProgressNoteBy.nurse
    : ProgressNoteBy.provider;
  const useFormContext = useForm<ProgressForm>();
  const {
    control,
    reset,
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = useFormContext;
  const formObject = getValues();
  const { fields, prepend, remove } = useFieldArray<
    ProgressForm,
    "items",
    "key"
  >({
    name: "items",
    control,
    keyName: "key",
  });
  const {
    data,
    isLoading,
    error: getError,
  } = useProgressNotes({ encounterId, noteBy });
  const toast = useToast();
  const dataLoaded = React.useRef(false);
  const dataUpdated = React.useRef(true);
  const {
    mutateAsync: createNote,
    isLoading: createIsLoading,
    data: newNote,
  } = useCreateProgressNote({ noteBy });
  const { mutateAsync: updateProgress, isLoading: updateIsLoading } =
    useUpdateProgressNotes();
  const onAddNewNote = async (type: ProgressNoteType) => {
    try {
      if (formObject.items.length > 0) {
        await updateProgress(
          formObject.items.map((x) => ({ ...x, encounterId }))
        );

        toast({ description: formMessages.updateSuccess("Progress") });
      }

      await createNote({
        notes: "",
        date: new Date(),
        encounterId,
        type,
      });
      toast({
        status: "success",
        description: formMessages.createSuccess("Progress Note"),
      });

      reset();
    } catch (error) {
      toast({ status: "error", description: extractApiErrorMessage(error) });
    }
  };
  const onSubmit = handleSubmit(async (values) => {
    try {
      await updateProgress(values.items.map((x) => ({ ...x, encounterId })));
      toast({ description: formMessages.updateSuccess("Progress") });
      // Reset form with latest values to set isDirty to false
      reset(values);
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  });

  const handleSaveAndNext = useSaveAndNext(
    onSubmit,
    "progress",
    "provider",
    isDirty
  );

  const onRouteChangeSave: FormPromptWithSaveProps["onSave"] = async (
    onComplete
  ) => {
    try {
      await updateProgress(
        formObject.items.map((x) => ({ ...x, encounterId }))
      );
      toast({ description: formMessages.updateSuccess("Progress") });
      onComplete();
    } catch (error) {
      toast({ description: extractApiErrorMessage(error) });
    }
  };

  React.useEffect(() => {
    if (newNote) {
      const { date, ...rest } = newNote.data;
      prepend({ ...rest, date: date && new Date(date) });

      // We reset this property to false to trigger the 'reset' function on the data
      // since there was a change in the API and we need to set isDirty to false
      dataUpdated.current = true;
    }
  }, [newNote, prepend]);

  React.useEffect(() => {
    if (getError) {
      toast({ description: extractApiErrorMessage(getError) });
    }
  }, [getError, toast]);

  React.useEffect(() => {
    if (data && (!dataLoaded.current || dataUpdated.current)) {
      reset({
        items: data.data.content.map(({ date, ...rest }) => ({
          date: date && new Date(date),
          ...rest,
        })),
      });
      dataLoaded.current = true;
      dataUpdated.current = false;
    }
  }, [data, reset]);

  const { isPastEncounter } = useChartTrackingContext();
  const isReadOnlyOrPastEncounter = isReadOnly || isPastEncounter;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <FormProvider {...useFormContext}>
      <ChartForm
        onSubmit={onSubmit}
        onRouteChangeSave={onRouteChangeSave}
        isDirty={isDirty}
        isPromptSaveLoading={updateIsLoading}
        display="flex"
        flexDirection="column"
        disabled={isReadOnly}
      >
        <SharedChartSectionHeader
          icon={<Chart />}
          encounterId={encounterId}
          sectionId="SPGR"
          showNotesBtn={chartType === "Chart" || chartType === "Triage"}
          showActionsBtns={fields.length > 0}
          isLoading={updateIsLoading}
          onSaveAndNext={handleSaveAndNext}
        >
          Progress {isReadOnly ? "(Read Only)" : ""}
        </SharedChartSectionHeader>
        <Box display="flex" justifyContent="flex-end" columnGap="25px">
          <Button
            variant="square"
            alignItems="center"
            disabled={isReadOnlyOrPastEncounter}
            onClick={() => onAddNewNote("transferCare")}
            isLoading={createIsLoading}
          >
            <Icon as={MdOutlineSwapVerticalCircle} fontSize="20px" mr="8px" />
            Transfer Care
          </Button>
          <Button
            variant="square"
            onClick={() => onAddNewNote("note")}
            disabled={isReadOnlyOrPastEncounter}
            isLoading={createIsLoading}
          >
            New Progress Note
          </Button>
        </Box>
        <ProgressCardHeader marginBottom="0.625rem" />
        <Box flex="1" overflow="auto">
          <VStack width="100%" spacing="16px" position="relative">
            {fields.map((progressNote, index) => (
              <ProgressCard
                isReadOnly={isPastEncounter}
                key={progressNote.key}
                name={`items.${index}`}
                width="100%"
                onDelete={() => {
                  remove(index);

                  // We reset this property to true to trigger the 'reset' function on the data
                  // since there was a change in the API and we need to set isDirty to false
                  dataUpdated.current = true;
                }}
                progressNote={progressNote}
              />
            ))}
          </VStack>
        </Box>
      </ChartForm>
    </FormProvider>
  );
}

export { Progress };
export type { ProgressForm };
