import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";
import { ChartProgressItem } from "types";

function useUpdateProgressNotes() {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["updateProgressNotes"],
    (input: ChartProgressItem[]) =>
      axiosChartInstance.put("v1/ProgressNote/bulk", input),
    {
      onSuccess: () => queryClient.invalidateQueries(["progressNotes"]),
    }
  );
}

export { useUpdateProgressNotes };
