import { RadioProps, useRadio, Box } from "@chakra-ui/react";
import * as React from "react";

type RadioChartOptionProps = RadioProps;

function RadioChartOption(props: RadioChartOptionProps) {
  const { children } = props;
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        _checked={{
          outline: "1px solid",
          outlineColor: "blue",
          color: "blue",
        }}
        borderRadius="20px"
        padding="7px 10px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        transition="all 200ms ease-in-out"
        color="gray.700"
        height="36px"
      >
        {children}
      </Box>
    </Box>
  );
}

export type { RadioChartOptionProps };
export { RadioChartOption };
