import * as React from "react";
import {
  chakra,
  ModalFooter,
  CloseButton,
  InputGroup,
  InputRightElement,
  HStack,
  IconButton,
  Icon,
  Spinner,
  Box,
} from "@chakra-ui/react";
import { EmrVoiceNote, EmrStop, EmrSendouts } from "@medstonetech/slate-icons";
import { useForm, SubmitHandler } from "react-hook-form";
import * as Yup from "yup";
import { useToast, useRecorder, useYupValidationResolver } from "hooks";
import { genericErrors } from "messages";
import { Input, AudioWave, InputProps } from "shared";
import { extractApiErrorMessage } from "utils";
import { UseMutateFunction } from "react-query";

type ChartNoteForm = {
  note: string;
};

type ChartNotesModalFooterProps = {
  onCreateNote: UseMutateFunction<unknown, unknown, ChartNoteForm>;
  onCreateAudioNote: UseMutateFunction<unknown, unknown, FormData>;
  isLoading: boolean;
  onCreateNoteSuccess?: () => void;
};

type TimerProps = {
  minutes: number;
  seconds: number;
};

const CHART_NOTE_FORM_SCHEMA = Yup.object().shape({
  note: Yup.string().required(),
});

function Timer(props: TimerProps) {
  const { minutes, seconds } = props;
  const timerStr = `${minutes < 10 ? 0 : ""}${minutes}:${
    seconds < 10 ? 0 : ""
  }${seconds}`;

  return (
    <chakra.span color="gray.700" width="50px">
      {timerStr}
    </chakra.span>
  );
}

function ChartNotesModalFooter(props: ChartNotesModalFooterProps) {
  const { onCreateNote, onCreateAudioNote, onCreateNoteSuccess, isLoading } =
    props;

  const { state, startRecording, saveRecording, cancelRecording } =
    useRecorder();
  const [isTyping, setIsTyping] = React.useState(false);

  const resolver = useYupValidationResolver(CHART_NOTE_FORM_SCHEMA);
  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting },
  } = useForm<ChartNoteForm>({
    resolver,
  });

  const toast = useToast();

  const onRecorderStop = React.useCallback(
    async (audio: Blob) => {
      try {
        const formData = new FormData();
        formData.append(
          "file",
          new File(
            [audio],
            `file.${audio.type.includes("mp4") ? "mp4" : "webm"}`,
            { type: audio.type }
          )
        );
        await onCreateAudioNote(formData);
        onCreateNoteSuccess?.();
      } catch (error) {
        toast({
          description:
            extractApiErrorMessage(error) || genericErrors.unknownError,
        });
      }
    },
    [toast, onCreateNoteSuccess, onCreateAudioNote]
  );

  const onSubmit: SubmitHandler<ChartNoteForm> = async (values) => {
    try {
      await onCreateNote(values);
      reset({ note: "" });
      setIsTyping(false);
      onCreateNoteSuccess?.();
    } catch (error) {
      toast({
        description:
          extractApiErrorMessage(error) || genericErrors.unknownError,
      });
    }
  };

  const { onChange: registerOnChange, ...restRegister } = register("note");
  const onChange: InputProps["onChange"] = (e) => {
    setIsTyping(!!e.currentTarget.value);
    registerOnChange(e);
  };
  const onKeyPress: InputProps["onKeyPress"] = (e) => {
    if (e.key === "Enter") {
      handleSubmit(onSubmit)(e);
    }
  };

  const showRecordBtn = !isLoading && !state.initRecording && !isTyping;

  React.useEffect(() => {
    if (state.audio) {
      onRecorderStop(state.audio);
    }
  }, [onRecorderStop, state.audio]);

  return (
    <ModalFooter height="60px" bg="gray.50" padding="0 60px">
      <Box width="100%" position="relative" display="flex" alignItems="center">
        {/* Show cancel recording button when recorder is active */}
        {state.initRecording && (
          <CloseButton
            width="20px"
            height="20px"
            bg="gray.700"
            color="white"
            borderRadius="50%"
            sx={{ svg: { width: "7px", height: "7px" } }}
            onClick={cancelRecording}
            position="absolute"
            left="calc(-20px - 1rem)"
          />
        )}
        <InputGroup>
          <Input
            placeholder="Type here"
            backgroundColor="white"
            borderRadius="25px"
            isDisabled={isSubmitting || state.initRecording || isLoading}
            onChange={onChange}
            onKeyPress={onKeyPress}
            {...restRegister}
          />
          <InputRightElement width="unset" right="1rem">
            {/* Show audio wave and timer when recorder is active */}
            {state.initRecording && (
              <HStack>
                <AudioWave />
                <Timer minutes={state.minutes} seconds={state.seconds} />
              </HStack>
            )}
            {/* Show start recording button when not loading and recorder is inactive */}
            {showRecordBtn && (
              <IconButton
                aria-label="start recording"
                icon={
                  <Icon as={EmrVoiceNote} color="gray.600" w="20px" h="20px" />
                }
                width="20px"
                height="20px"
                variant="ghost"
                minWidth="unset"
                onClick={startRecording}
              />
            )}
            {/* If note is being uploaded show a loading */}
            {isLoading && <Spinner width="20px" height="20px" color="blue" />}
            {/* If the user is typing show the submit button */}
            {isTyping && !isLoading && (
              <IconButton
                aria-label="submit note"
                icon={<Icon as={EmrSendouts} color="white" w="20px" h="20px" />}
                width="24px"
                height="24px"
                variant="ghost"
                minWidth="unset"
                bg="blue"
                onClick={handleSubmit(onSubmit)}
              />
            )}
          </InputRightElement>
        </InputGroup>
        {/* If recorder is active show the save recording button */}
        {state.initRecording && (
          <IconButton
            aria-label="save recording"
            icon={<Icon as={EmrStop} color="red" w="20px" h="20px" />}
            width="20px"
            height="20px"
            variant="ghost"
            minWidth="unset"
            onClick={saveRecording}
            position="absolute"
            right="calc(-20px - 1rem)"
          />
        )}
      </Box>
    </ModalFooter>
  );
}

export type { ChartNotesModalFooterProps };
export { ChartNotesModalFooter };
