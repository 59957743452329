import { axiosChartInstance } from "config";
import { OrderGroup } from "enums";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type DeleteOrderChartNoteParams = {
  encounterId: string;
  chartCode: string;
  orderType: OrderGroup;
  noteId: string;
};

function useDeleteOrderChartNote(params: DeleteOrderChartNoteParams) {
  const { encounterId, chartCode, orderType, noteId } = params;
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["deleteOrderChartNote", params],
    () =>
      axiosChartInstance.delete(
        `v1/${encounterId}/${chartCode}/order/note/${noteId}`,
        {
          params: { orderType },
        }
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          "orderChartNotes",
          { encounterId, chartCode, orderType },
        ]),
    }
  );
}

export { useDeleteOrderChartNote };
