import { axiosChartInstance } from "config";
import { useAxiosMutation } from "hooks";
import { ChartFormSubsectionPayload } from "modules/charts-shared";
import { useQueryClient } from "react-query";

function useUpdateProviderMedicalHistory(encounterId: string) {
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["chartApplication", { encounterId }],
    (input: ChartFormSubsectionPayload[]) =>
      axiosChartInstance.post(`v1/chart/${encounterId}/medical-history`, input),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          "providerMedicalHistory",
          { encounterId },
        ]),
    }
  );
}

export { useUpdateProviderMedicalHistory };
