import { axiosChartInstance } from "config";
import { OrderGroup } from "enums";
import { useAxiosMutation } from "hooks";
import { useQueryClient } from "react-query";

type ExportOrderChartNoteParams = {
  encounterId: string;
  chartCode: string;
  chartOrderSectionNote: string;
  isExportable: boolean;
  orderType: OrderGroup;
};

function useExportableOrderChartNote(params: ExportOrderChartNoteParams) {
  const {
    encounterId,
    chartCode,
    chartOrderSectionNote,
    isExportable,
    orderType,
  } = params;
  const queryClient = useQueryClient();
  return useAxiosMutation(
    ["exportOrderChartNote", params],
    () =>
      axiosChartInstance.put(
        `v1/${encounterId}/${chartCode}/order/note/update-exportable/${chartOrderSectionNote}/${isExportable}`,
        null,
        {
          params: { orderType },
        }
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          "orderChartNotes",
          { encounterId, chartCode, orderType },
        ]),
    }
  );
}

export { useExportableOrderChartNote };
